import './Footer.css'

function Footer(){
    return(
        <div className='footer'>
            <p className='privacy_pol'>
                <a className='footer_link' href='/privacy'>Privacy Policy </a>|<a className='footer_link' href='/disclaimer'> Disclaimer</a>
            </p>
            <p className='marca'>
                Associazione Italiana Ricerca e Informazione per la Vitiligine - ONLUS
            </p>
        </div>
    )
}

export default Footer;