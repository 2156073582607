import './DomandeERisposte.css'
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import React from "react";
import Footer from "../Footer/Footer";
import LaDIAGNOSI from "../LaDIAGNOSI/LaDIAGNOSI";
import LETERAPIE from "../LETERAPIE/LETERAPIE";
import PROBLEMI from "../PROBLEMI/PROBLEMI";
function DomandeERisposte() {
    return (
        <div className='domande_e_risposte'>
            <Header/>
            <div className='domande_e_risposte_content'>
                <Menu/>
                <div className='domande_e_risposte_filling'>
                    <div id="mainarea">
                        <div className="catgroupnamearea">
                            <h1 className="heading">Domande e risposte » </h1>
                        </div>

                        <div id="maincontarea">
                            <div align="justify">
                                <p><strong> <span className="Titolo">Queste informazioni non possono in alcun modo sostituirsi alle prescrizioni e alle cure del dermatologo, che è necessario consultare prima  di intraprendere qualunque terapia.</span></strong>
                                </p>
                                <p className="importante"><span className="Testo"><br/>
IMPORTANTE. Queste pagine hanno uno scopo   esclusivamente informativo. Ogni sforzo è stato condotto per renderle chiare,   aggiornate, facilmente comprensibili da un pubblico molto vasto; tuttavia non   possiamo escludere eventuali omissioni ed errori come anche possibili difficoltà   interpretative da parte dei lettori. &nbsp;La dermatologia è in costante evoluzione   ed ogni paziente è unico nella sua condizione clinica; ribadiamo quindi che   &nbsp;solo al vostro Dermatologo spetta ogni conclusione diagnostica e terapeutica.   Non rispondiamo in alcun modo di un uso improprio e non autorizzato delle   informazioni fornite</span>.
                                </p>
                                <p align="left"><strong><span className="Titolo">LEGGENDE METROPOLITANE</span></strong>
                                </p>
                                <span className="SottoTitolo">
<p><strong><em><br/>
Mezze  verità, incertezze, leggende metropolitane, cure miracolose…. non solo in  internet, ma anche in campo medico la diagnosi&nbsp;  genera spesso &nbsp;incertezze.</em></strong></p>
<p><strong><em><br/>
Ecco un po’ di  chiarezza su&nbsp; alcune delle più  comuni ”leggende metropolitane”</em></strong></p>
<p><strong><br/>
- La terapia con le vitamine&nbsp; fa repigmentare:</strong><span className="Testo"><br/>
Non esiste evidenza da studi clinici che l’assunzione di  vitamine e integratori possa&nbsp; repigmentare  o prevenire l’espansione della malattia. (ci sono però studi clinici che  confermano che alcune terapie possono avere risultati migliori se abbinate  all’uso di vitamine e integratori)</span></p>
</span>
                                <p><span className="SottoTitolo"><strong><br/>
- La vitiligine è un  sintomo di altre malattie interne</strong></span><br/>
                                    <span className="Testo">Alcuni &nbsp;pazienti di  vitiligine presentano un rischio maggiore di altri di sviluppare malattie  autoimmuni, come ad esempio malattie della tiroide, alopecia areata, anemia  perniciosa ecc.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- La pelle normale può  diventare bianca in sede di una ferita, trauma, scottatura, graffio ecc.</strong></span><br/>
                                    <span className="Testo">In effetti una sede di trauma  continuo (reggiseno, cinture, jeans stretti ecc) o un taglio, un graffio, possono  diventare sede di &nbsp;nuove chiazze di vitiligine.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- Mangiare&nbsp;  alcuni cibi può peggiorare la malattia</strong></span><br/>
                                    <span className="Testo">Non c’è nessun riscontro per questa affermazione. Una dieta  sana e ben bilanciata è importante per chi soffre di vitiligine, come per chi  non ne è affetto.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- Esporsi al sole può peggiorare  la malattia</strong></span><br/>
                                    <span className="Testo">L’esposizione “selvaggia” può provocare scottature alle zone  con vitiligine, le scottature possono provocare l’estendersi della malattia.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- Lo stress può  scatenare o peggiorare la malattia</strong></span><br/>
                                    <span className="Testo">Nella letteratura medica non c’è riscontro su questo  argomento, molti pazienti&nbsp; però mettono in  relazione&nbsp; un episodio di stress psichico  o fisico &nbsp;con il presentarsi o  l’estendersi della malattia.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- E’ probabile che membri  della mia famiglia&nbsp; sviluppino la  malattia</strong></span><br/>
                                    <span className="Testo">Se hai la vitiligine, c’è un 6% di possibilità di svilupparla, per i tuoi consanguinei. La maggior parte dei pazienti però riferisce che nella  famiglia non ci sono casi di vitiligine.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- La vitiligine può  colpire&nbsp; chiunque a qualunque età</strong></span><br/>
                                    <span className="Testo">E’ vero, comunque &nbsp;la  metà dei pazienti di vitiligine è al di sotto dei 20 anni, quindi bambini e  adolescenti.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- Mangiare carote ed  arance può aiutare a repigmentare</strong></span><br/>
                                    <span
                                        className="Testo">I carotenoidi si trovano abbondanti in frutta e verdura &nbsp;a forte colorazione, come le carote, e consumati  in grande quantità possono dare temporaneamente alla pelle un colore un po’  abbronzato &nbsp;aranciato, ma non migliorano  la situazione estetica.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- Alcuni si  ripigmentano spontaneamente,senza cure.</strong></span><br/>
                                    <span className="Testo">Sì, non è eccezionale che alcune chiazze si ripigmentino da  sole, senza terapie, comunque una repigmentazione totale è un evento eccezionalmente  raro.</span>
                                </p>
                                <p><span className="SottoTitolo"><strong><br/>
- La vitiligine è più  difficile da curare se è di lunga data</strong></span><br/>
                                    <span className="Testo">Può essere vero che una vitiligine recente si cura più  facilmente, però anche chiazze di vecchia data possono re pigmentarsi.</span>
                                </p>
                                <p><span className="SottoTitolo"><strong><br/>
- Esistono vari tipi  di vitiligine</strong></span><span className="Titolo"><br/>
                                </span><span className="Testo">E’ vero, c’è quella generalizzata,con chiazze  simmetriche, mentre quella segmentaria si manifesta su una sola parte del  corpo,&nbsp; o in modo asimmetrico.</span>
                                </p>
                                <p><span className="Titolo"><strong><br/>
- Chi ha la vitiligine  è più propenso a contrarre cancro della pelle</strong></span><br/>
                                    <span className="Testo">Non c’è nessuna evidenza scientifica su questo argomento. Comunque  è sempre bene usare schermo solare per proteggere la pelle pigmentata e non dai  raggi&nbsp; UV potenzialmente nocivi.</span>
                                </p>
                                <p align="center" className="Stile1 Stile2"><span className="Titolo"><br/>
<br/>
</span></p>
                                <div align="left"><strong> LA VITILIGINE</strong></div>
                                <p>&nbsp;</p>
                                <span className="Testo"><p>La vitiligo (leucodermia) è una malattia abbastanza frequente, che si manifesta sulla pelle con chiazze bianche, rotonde od ovali, a bordi leggermente irregolari e ben delimitati; col tempo possono ingrandirsi e confluire. La pelle peraltro è normale, il decorso quasi sempre lento e progressivo.</p><p>Non è contagiosa, le cause non sono note, tuttavia la ricerca si sta avvicinando sempre di più alla comprensione dei meccanismi genetici, immunologici e biochimici che stanno alla base della malattia. Ci può essere una predisposizione genetica, in quanto, anche se non sempre, compare nei consanguinei. Si pensa che sia una malattia su base autoimmune: il sistema immunitario, per errore, attacca le cellule che producono il pigmento, riconoscendole come estranee.</p><p>Nel mondo ha una diffusione stimata intorno all’1-2%, quindi 100.000 milioni circa di pazienti. E’ molto sfigurante nelle persone di pelle scura o in chi si abbronza facilmente. È menzionata nella Bibbia e nel Corano, nonché in antichi testi egiziani e cinesi. In alcuni paesi l’incidenza è maggiore, per ragioni genetiche e sociali. Nel 95% dei pazienti la malattia compare prima dei 40 anni, spesso tra i 10 e i 30 anni. Colpisce tutte le razze e ugualmente i due sessi senza predilezione etnica o socioeconomica. Studi sulla qualità della vita confermano che è una delle malattie della pelle più psicologicamente devastanti.</p>
                                </span>
                                <p>&nbsp;</p>
                                <p><span className="Titolo"><strong>LA RICERCA<br/>
<br/>
</strong></span></p>
                                <span className="Testo">
<p>Nei prossimi anni, grazie all’evoluzione delle tecnologie di indagine genetica e bioingegneristica, emergeranno nuove conoscenze. La ricerca spazia dalla genetica alle malattie autoimmuni, alla struttura della pelle, alla conoscenza del sistema immunitario.</p>
<p>Le biotecnologie migliorano, così la comprensione del genoma umano, e le attrezzature sempre più sofisticate dei laboratori facilitano i ricercatori; maggiori conoscenze porteranno a terapie nuove e più soddisfacenti.</p>
<p><b>ARIV</b> stimola e promuove la ricerca finché si arriverà ad una cura definitiva:<br/>
<a href=""> puoi aiutare i nostri sforzi partecipando attivamente, diventando socio ARIVONLUS e/o facendo una donazione.</a></p>
</span>
                                <p>&nbsp;</p>
                                <p align="center"><span className="Titolo"><strong><br/>
</strong></span></p>
                                <div align="left"><span className="Titolo"><strong> LE</strong> <strong>DOMANDE PIÙ FREQUENTI<br/>
<br/>
</strong></span></div>
                                <p><strong><span className="Titolo">LA DIAGNOSI<br/>
<br/>
</span></strong></p>
                                <LaDIAGNOSI/>
                                <br/>
                                <br/>
                                <br/>
                                <strong>LE TERAPIE<br/>
                                    <br/>
                                    <LETERAPIE/>
                                </strong>
                                <br/>
                                <br/>
                                <br/>
                                <strong>I PROBLEMI PSICOLOGICI E RELAZIONALI<br/>
                                    <br/>
                                    <PROBLEMI/>
                                </strong>
                                <p><span
                                    className="Testo">Queste sono le domande più frequenti rivolte dai  pazienti.<br/>
<br/>
Nessuna parte di questo testo può essere copiata,  stampata, tradotta senza il permesso di ARIVONLUS (tranne che per l¹uso  personale o per il proprio dermatologo).</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default DomandeERisposte;