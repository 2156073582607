import './LETERAPIE.css'
import DescriptionToggle from "../DescriptionToggle/DescriptionToggle";
import React from "react";

function LETERAPIE() {
    const desc1 = <td className="DomRisp"><span className="Testo">La terapia PUVA orale è indicata per chi ha una vitiligine estesa a più del 20% del corpo o per chi non risponde alla terapia PUVA topica. PUVA significa Psoraleni (sostanze fotosensibilizzanti che si trovano in natura  in più di 30 piante comuni) + ultravioletti A. Gli psoraleni, con la luce del sole, sono usati da centinaia di anni per curare la malattia.
            <br/>
            Nella terapia PUVA orale, il paziente prende una dose stabilita di psoraleni, per bocca, circa due ore prima di esporsi alla lampada UVA o al sole. Il dermatologo stabilisce la quantità di psoralene e di luce, in modo che le aree trattate diventino rosa. Questa cura si effettua due o tre volte la settimana, non in due giorni di fila. Il paziente deve evitare la luce del sole per 12-24 ore dopo la terapia, e usare occhiali da sole avvolgenti per proteggere gli occhi.
            <br/>
            Gli effetti collaterali più comuni della terapia PUVA sono scottature, nausea, diarrea. Se non si vede miglioramento entro tre mesi, la terapia viene generalmente sospesa. La percentuale di ripigmentazione che si ottiene ,e la sua durata, variano da paziente a paziente.</span>
    </td>
    const desc2 = <span className="Testo">Pomate come Elocon, Clobetasol ecc. possono essere utili per ripigmentare, soprattutto nella vitiligo localizzata e recente. Vengono usate sia come monoterapia che come coadiuvante di altre modalità terapeutiche. Come coadiuvanti,i preparati cortisonici si sono dimostrati utili nella vitiligo localizzata, con esposizione al sole per 10 minuti al dì inizialmente, e in seguito fino a 20-30 minuti. I dermatologi prescrivono spesso una crema leggera per i bambini sotto i dieci anni e concentrazioni più alte per gli adulti.
            Il paziente va controllato frequentemente perché gli steroidi possono danneggiare e assottigliare la pelle nonché provocare  acne o smagliature. Per questo motivo non possono essere usati per lunghi periodi di tempo. Ecoval viene spesso consigliato per il viso.</span>
    const desc3 = <td className="DomRisp"><span className="Testo">Si effettua in ospedale una o due volte la settimana solo se il paziente ha un limitato numero di piccole chiazze. Viene applicato dal medico un sottile strato di tintura a base di psoraleni, sostanze fotosensibilizzanti, sulle chiazze, circa mezz’ora prima dell’esposizione e la dose di UVA viene leggermente aumentata di volta in volta.
            <br/>
            Dopo ogni trattamento, il paziente deve lavare la pelle con acqua e sapone e applicare uno schermo solare prima di uscire. Severe bruciature e a volte iperpigmentazione (temporanea) delle chiazze possono essere effetti collaterali molto indesiderati.</span>
    </td>
    const desc4 = <span className="Testo">Dalla fine degli anni ’80 iniziava una nuova terapia con lampade a raggi ultravioletti con un picco di emissione intorno a 311 nanometri (TL01, Philips) per la psoriasi e l’eczema. Più recentemente, agli inizi del 2000, questa terapia si sperimentava anche per la vitiligo generalizzata. La dose iniziale è 0,03J/cm2 (per tutti i tipi di pelle) e viene aumentata nelle successive sedute del 10-20% fino a che un leggero arrossamento (eritema) compare nelle chiazze bianche.
            <br/>
            I vantaggi della terapia UVB rispetto alla PUVA includono tempi di trattamento più brevi, non si prendono farmaci per bocca, si evidenzia meno il contrasto con la pelle normale perché abbronzano poco, non c’è bisogno di proteggere gli occhi dopo il trattamento. Assoluta controindicazione per la terapia UVB,che si effettua due-tre volte per settimana, sono reazioni fototossiche, uso concomitante di farmaci fotosensibilizzanti, pelle di fototipo 1, storia di cancro della pelle, concomitante terapia radio-chemio o immunosoppressiva, claustrofobia, pazienti sotto i 6 anni, vitiligo delle mucose. Effetti collaterali a breve termine possono essere herpes, eczema erpetico, prurito; mancano dati sul lungo termine.
            <br/>
            La terapia si effettua nel reparto di fototerapia degli Ospedali o presso centri privati. Esistono anche lampade per la cura domiciliare che comunque devono sempre essere usate sotto stretto controllo medico. Se ci sono risultati entri 6 mesi, la terapia viene in genere continuata per un anno o più.</span>
    const desc5 = <span className="Testo">Protopic (Tacrolimus) in unguento, prodotto dalla Fujisawa Pharmaceuticals,ed Elidel (Pimecrolimus),della Novartis, in crema, agiscono nello stesso modo, regolando la risposta immunitaria nella pelle.Piccoli studi da varie parti del mondo riportano risultati di ripigmentazione in alcuni pazienti (non in tutti).
            <br/>
            Sono entrambi farmaci indicati per la dermatite atopica (eczema)da usarsi per brevi  intermittenti periodi, e non riportano l’indicazione “vitiligine”, perché non sono approvati per questa patologia. Sono disponibili in due concentrazioni: 0,3%, e 0,1%. Essendo farmaci nuovi, rischi ed effetti collaterali non sono ancora ben noti. Nel 2005 si diffuse molto allarmismo perché vennero riferiti casi di linfoma e cancro alla pelle in seguito al loro uso.</span>
    const desc6 = <></>
    const desc7 = <></>
    const desc8 = <span className="Testo">È un analogo di sintesi della vitamina D3, inibisce l'attivazione delle T-cells. Alcuni piccoli studi hanno dimostrato la sua efficacia in combinazione con terapia UV in pazienti con vitiligo, sia bambini che adulti, altri studi hanno riportato risultati scarsi o nulli.</span>
    const desc9 = <span className="Testo">È una crema che va applicata due volte al giorno su tutto il corpo, con lo scopo di ridurre il perossido di idrogeno(acqua ossigenata) che sembra accumularsi nella pelle dei pazienti con vitiligine,secondo la teoria che vede lo stress ossidativo come responsabile della morte-scomparsa dei melanociti.
            <br/>
            La terapia è completata con UVB a banda stretta, o con la luce del sole. Non esistono studi controllati e indipendenti che supportino l’efficacia dello pseudocatalase (P-KUS). La crema viene spedita direttamente al paziente dalla Germania.e in tutto il mondo, dalla Prof. Schallreuter, in confezioni da 500 gr. (dura un mese) previa visita al paziente cui viene chiesto di recarsi a Greiswald sia per una prima visita, sia per i successivi controlli.</span>
    const desc10 = <td className="DomRisp"><span className="Testo">È una sostanza che si ricava dai semi della pianta Amni Visnaga. Il dermatologo prescrive una preparazione in crema, in concentrazione dal 3 al 5%, che viene preparata dal farmacista.
            <br/>
            Si applica sulle chiazze 30 minuti prima di esporsi al sole, il meccanismo di azione sembra simile a quello degli psoraleni</span>.
    </td>
    const desc11 = <td className="DomRisp"><span className="Testo">È un raggio di luce UVB ad alta concentrazione che viene diretto esclusivamente sulle chiazze, la pelle normalmente pigmentata viene così risparmiata. La terapia viene pratica negli ospedali attrezzati e/o presso studi privati.
            <br/>
            I risultati migliori si hanno su viso e collo, dopo una trentina di sedute. Alcuni studi riferiscono ripigmentazione più veloce e completa con terapia combinata laser eccimeri + Tacrolimus.Anche in questo caso, la durata di una eventuale ripigmentazione varia da paziente a paziente.</span>
    </td>
    const desc12 = <td className="DomRisp"><span className="Testo">È un trapianto autologo (dallo stesso paziente): il medico rimuove una piccola area di pelle normalmente pigmentata e la trapianta su una zona di vitiligine dello stesso paziente.
            <br/>
            E’ una tecnica usata per piccole chiazze. Le complicazioni possono essere infezioni, cicatrici o mancanza di pigmentazione.</span>
    </td>
    const desc13 = <span className="Testo">Le terapie chirurgiche sono un’opzione per  piccole chiazze di vitiligo, che non si ripigmentano con altre terapie. E sono controindicate per i pazienti con una storia di cheloidi o di cicatrici ipertrofiche. Il chirurgo preleva dal paziente una piccolissima parte di pelle normalmente pigmentata e la mette in una soluzione speciale che moltiplica i melanociti, che vengono successivamente trapiantati nelle chiazze di vitiligo.
            <br/>
            Pare che i risultati migliori e duraturi si abbiano nella vitiligine segmentaria. Il trattamento chirurgico della vitiligo è rigorosamente riservato a casi selezionati in base a criteri ben definiti per estensione, epoca di insorgenza e stabilità della malattia.</span>
    const desc14 = <span className="Testo">È una crema a base di placenta umana o animale, messa a punto a Cuba 30 anni fa. I pazienti dichiarano generalmente di non aver avuto benefici da questa terapia, mentre il medico cubano che l'ha introdotta dichiara successo duraturo di ripigmentazione nei suoi pazienti.
            <br/>
            Ora è disponibile a Cuba la Melagenina Plus che richiede una sola applicazione al giorno, senza bisogno di esposizione solare. Non esistono studi scientifici controllati che supportino l’efficacia di questa cura, che in Italia anni fa veniva effettuata in una clinica a San Marino, successivamente chiusa.</span>
    const desc15 = <td className="DomRisp"><span className="Testo">Sono creme che contengono vitamine, fitonutrienti e antiossidanti contro la produzione di radicali liberi a livello epidermico. È necessaria l’esposizione al sole.
            <br/>
            Qualche paziente riporta qualche risultato, il loro scopo è stimolare il processo di ripigmentazione, non esistono studi scientifici che convalidano la loro efficacia.</span>
    </td>
    const desc16 = <td className="DomRisp"><span className="Testo">Se la vitiligine è estesa più del 70% c’è la possibilità di depigmentare la pelle scura rimasta, per diventare di un solo colore. La sostanza più usata per schiarire è il monobenziletere di idrochinone. I pazienti, sotto la guida del dermatologo, applicano alle poche zone pigmentate rimaste e che desiderano schiarire, una crema a base di questa sostanza, in genere a una concentrazione del 20% (può dare problemi di arrossamento e di gonfiore nonché prurito e pelle secca).
            <br/>
            La depigmentazione  avviene lentamente, da 6 mesi a due anni, è generalmente permanente, si diventa più sensibili alla luce del sole. Anche la crioterapia è ritenuta efficace in pazienti con vitiligo universale: per raggiungere risultati, è necessario sottoporsi ad 1/3 sessioni ad un intervallo di 4 settimane. Una valida opzione per la depigmentazione è anche il laser Ruby QS (694 nm).</span>
    </td>
    const desc17 = <span className="Testo">Mani, piedi e zone dove peli e capelli sono diventati bianchi sono generalmente più difficili da trattare, ma  esistono anche  eccezioni.</span>
    const desc18 = <td className="DomRisp"><span className="Testo">Molti esperti consigliano di non usare i solarium perché emettono uno spettro di raggi UV più forte e più largo e quindi non sono così efficaci ed innocui per la vitiligine. Prima di intraprendere qualunque tipo di fototerapia è doveroso consultare il dermatologo.</span>
    </td>
    const desc19 = <td class="DomRisp"><span className="Testo">Questo è un argomento delicato che il paziente spesso non sa come affrontare. In realtà molti pazienti hanno la vitiligine anche sui genitali, il che interferisce con la loro vita sociale e sessuale.
            <br/>
            Alcuni dermatologi usano steroidi topici, immunomodulatori e ultimamente, in casi selezionati, trapianto di melanociti. La fototerapia è controindicata.</span>
    </td>
    const desc20 = <span className="Testo">Prima di iniziare una fototerapia è bene sottoporsi ad una visita oculistica. Il dermatologo obbliga il paziente ad usare gli speciali occhiali durante la terapia UV perché il rischio è la cataratta; a qualcuno viene permesso di toglierli e di tenere gli occhi chiusi quando le chiazze da curare sono sulle palpebre. Però la luce passa anche dalla pelle estremamente sottile della palpebra.</span>
    const desc21 = <td class="DomRisp"><span class="Testo">Con le cure disponibili si tenta di ripigmentare i sintomi (le chiazze), perchè le cause della malattia non sono note. Quando una chiazza si ripigmenta,soprattutto se si è ottenuta una ripigmentazione completa,la repigmentazione può durare a lungo. Un numero significativo di pazienti riferisce che, qualche tempo dopo aver interrotto le terapie, il pigmento faticosamente guadagnato svanisce di nuovo.
            <br/>
            Con i progressi della ricerca, saremo in grado un giorno poter fermare il progredire della malattia, di curarla con successo e di prevenirla intervenendo sulle cause.</span></td>


    return (
        <>
            <DescriptionToggle description='• Che cos’è la terapia PUVA?' link={desc1}/>
            <DescriptionToggle description='• Cosa sono una crema o un unguento steroideo?' link={desc2}/>
            <DescriptionToggle description='• Cos’è la terapia topica con gli psoraleni?' link={desc3}/>
            <DescriptionToggle description='• Cos’è l’UVB a banda stretta?' link={desc4}/>
            <DescriptionToggle description='• Che cosa sono Protopic e Elidel?' link={desc5}/>
            <DescriptionToggle description='• Cosa sono Aldara e Imiquimod?' link={desc6}/>
            <DescriptionToggle description='Sono anch’essi immunomodulatori.' link={desc7}/>
            <DescriptionToggle description='• Cos’è il Calcipotriol?' link={desc8}/>
            <DescriptionToggle description='• Cos’è lo pseudocatalase (P-cat, P/KUS)?' link={desc9}/>
            <DescriptionToggle description='• Cos’è la Kellina?' link={desc10}/>
            <DescriptionToggle description='• Cos’è il laser a eccimeri?' link={desc11}/>
            <DescriptionToggle description='• Cos’è lo skin grafting?' link={desc12}/>
            <DescriptionToggle description='• Cos’è il trapianto autologo di melanociti?' link={desc13}/>
            <DescriptionToggle description='• Cos’è la Melagenina?' link={desc14}/>
            <DescriptionToggle description='• Cosa sono Novitil/Sinvitil/Demabest/Vitix?' link={desc15}/>
            <DescriptionToggle description='• Cos’è la depigmentazione?' link={desc16}/>
            <DescriptionToggle description='• Ci sono parti del corpo più difficili di altre da curare?' link={desc17}/>
            <DescriptionToggle description='• I lettini abbronzanti possono sostituire la PUVA o l’UVB nb?'
                               link={desc18}/>
            <DescriptionToggle description='• Si possono curare anche i genitali?' link={desc19}/>
            <DescriptionToggle description='• Sono senza rischi le cure al viso e al contorno occhi?' link={desc20}/>
            <DescriptionToggle
                description='• Il pigmento recuperato con le terapie è permanente o dovrò curarmi per sempre?'
                link={desc21}/>
        </>
    )
}

export default LETERAPIE;