import './HomePage.css'
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import img1 from '../images/eadv2019.jpg'
import button from '../images/leggi.jpg'
import box1 from '../images/Siderp.jpg'
import box2 from '../images/logo_international.jpg'
import box3 from '../images/box-news3hp.jpg'
function HomePage(){
    return(
        <div className='home_page'>
            <Header/>
            <div className='content'>
                <Menu/>
                <div className='content_filling'>
                    {/*Column 1*/}
                    <div className='column_1'>
                        <h1 className='heading'>
                            Appuntamenti e Congressi
                        </h1>
                        <p><span className="Testo">ARIV sarà presente a:</span><br/>
                            <span className="SottoTitolo"><strong>28th European Academy of Dermatology<br/>
                            9-13 October<br/>
                            Madrid, Spain</strong><br/>
                            <br/>
                            </span>
                        </p>
                        <img src={img1}/>
                        <strong>Vitiligine e nuove terapie</strong>
                        <iframe width="auto" height="230"
                                src="https://www.youtube.com/embed/wxvcHAwwvCI?si=LLvqiRqoLk0mpg4A"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen></iframe>
                        <h1 className='heading'>
                            Partners
                        </h1>
                        <a href='https://www.mur.gov.it/it'><strong>» MUR</strong></a>
                        <a href='https://www.fondazioneveronesi.it/'><strong>» Fondazione Veronesi</strong></a>
                        <a href='https://www.lenostube.com/'><strong>» Lenos </strong></a>
                        <a href='https://www.lastampa.it/'><strong>» La Stampa </strong></a>
                    </div>

                    {/*Column 2*/}
                    <div className='column_2'>
                        <h1 className='heading_blue'>News</h1>

                        <div className='boxes'>
                            <div className='box'>
                                <number>01.</number>
                                <div className='box_content'>
                                    <box_heading>VI Congresso Nazionale S.I.Der.P.</box_heading>
                                    <img src={box1}/>
                                    <br/>
                                    <box_heading>Bologna, 12-14 Ottobre</box_heading>
                                    <p>PALAZZO DELLA CULTURA E DEI CONGRESSI
                                        <br/>
                                        Piazza della Costituzione, 4 - Bologna</p>
                                </div>
                            </div>

                            <div className='box'>
                                <number>02.</number>
                                <div className='box_content'>
                                    <box_heading>Vitiligo International Symposium</box_heading>
                                    <img src={box2}/>
                                    <br/>
                                    <box_heading>Roma, 2-3 Dicembre</box_heading>
                                </div>
                            </div>

                            <div className='box'>
                                <number>03.</number>
                                <div className='box_content'>
                                    <box_heading>NON MALTRATTIAMO LA NOSTRA PELLE!</box_heading>
                                    <img src={box3}/>
                                    <br/>
                                    <p>Pochi sanno che la nostra pelle è l’organo più grande che abbiamo: raggiunge in una persona adulta una superficie anche di 2 metri quadrati e il suo spessore varia ...</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default HomePage;