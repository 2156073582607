import HomePage from "./HomePage/HomePage";
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import DallaRicerca from "./DallaRicerca/DallaRicerca";
import ComitatoScientifico from "./ComitatoScientifico/ComitatoScientifico";
import DomandeERisposte from "./DomandeERisposte/DomandeERisposte";
import LaTuaStoria from "./LaTuaStoria/LaTuStoria";
import Contatti from "./Contatti/Contatti";
import Disclaimer from "./Disclaimer/Disclaimer";
import Privacy from "./Privacy/Privacy";
function App() {
  return (
      <Router>
          <Routes>
              <Route path='/homepage' element={<HomePage />} /><Route path='/' element={<HomePage />} />

              <Route path='/dalla_ricerca' element={<DallaRicerca/>} />
              <Route path='/comitato_scientifico' element={<ComitatoScientifico/>} />
              <Route path='/domande_e_risposte' element={<DomandeERisposte/>} />
              <Route path='/la_tua_storia' element={<LaTuaStoria/>} />
              <Route path='/contatti' element={<Contatti/>} />

              <Route path='/disclaimer' element={<Disclaimer/>} />
              <Route path='/privacy' element={<Privacy/>} />




          </Routes>
      </Router>
  );
}

export default App;
