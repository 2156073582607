import './Disclaimer.css'
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";

function Disclaimer(){
    return(
        <div className='disclaimer'>
            <Header/>
            <div className='disclaimer_content'>
                <Menu/>
                <div className='disclaimer_filling'>
                    <h1 className='heading'>Contatti » Disclaimer »</h1>
                    <p><span className="Testo">Questo documento, concernente le politiche di riservatezza dei dati personali del sito internet www.arivonlus.it, intende descrivere nella maniera più accurata le modalità di gestione del trattamento dei dati personali degli utenti e visitatori del nostro sito.<br/>
<br/>
Questa è, inoltre, una informativa resa anche ai sensi dell'art. 13 del Decreto Legislativo 30 giugno 2003 n. 196 per gli utenti dei servizi del nostro sito, che vengono erogati via Internet. L'informativa non è valida per altri siti web eventualmente consultabili tramite i nostri links, di cui non siamo in alcun modo responsabili.  Le indicazioni fornite di seguito si ispirano anche alla Raccomandazione n. 2/2001 del Gruppo dei Garanti Europei per la protezione dei dati personali adottata il 17 maggio 2001 ex art. 29 della direttiva n. 95/46/CE, riguardano in modo particolare la raccolta di dati personali su Internet e si prefiggono di identificare le misure minime che dovranno essere attuate nei confronti delle persone interessate per garantire la lealtà e la liceità di tali pratiche (in applicazione degli articoli 6, 7, 10 e 11 della direttiva 95/46/CE).<br/>
<br/>
<span>• il "titolare" del trattamento</span><br/>
"Titolare" del trattamento dei dati personali eventualmente trattati a seguito della consultazione del nostro sito e di ogni altro dato utilizzato per la fornitura dei nostri servizi, è ARIV Onlus, nella persona di Simona Gerosa.<br/>
<br/>
<span>• diritti degli interessati</span><br/>
I soggetti cui si riferiscono i dati personali, ai sensi dell'art. 7 del D.Lgs. n.196/2003, hanno il diritto in qualunque momento di ottenere la conferma dell'esistenza o meno dei medesimi dati e di conoscerne il contenuto e l'origine, verificarne l'esattezza o chiedere di integrarli, aggiornarli, oppure rettificarli. I soggetti cui si riferiscono i dati personali hanno, inoltre, il diritto di chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento. Le richieste relative all'art. 7 del D.Lgs. n.196/2003 devono essere rivolte a Simona Gerosa - via Monza, 50 - Cernusco Bombardone (Lc) ovvero all’indirizzo e-mail: <a
                            href="">info@arivonlus.it</a><br/>
<br/>
<span>• luogo e finalità del trattamento dei dati</span><br/>
I trattamenti connessi ai servizi web offerti da questo sito, fisicamente posto "in hosting" presso la società Aruba S.p.A. di Soci (Ar), sono effettuati presso la sede del titolare del trattamento e sono curati solo da titolari, dipendenti, collaboratori di Simona Gerosa incaricati del trattamento, o da eventuali incaricati di occasionali operazioni di manutenzione. Nessun dato derivante dai servizi web viene comunicato o diffuso. I dati personali forniti dagli utenti che inoltrano richieste di servizio, sono utilizzati al solo fine di eseguire la consulenza, il servizio o la prestazione richiesta e non sono comunicati a soggetti terzi, salvo che la comunicazione sia imposta da obblighi di legge o sia strettamente necessaria per l’adempimento delle richieste.<br/>
<br/>
<span>• tipologia di dati trattati</span><br/>
dati di navigazione<br/>
I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell'utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull'uso del sito e per controllarne il corretto funzionamento e vengono cancellati immediatamente dopo l'elaborazione. I dati in questione potrebbero essere utilizzati per l'accertamento di responsabilità in caso di eventuali reati informatici ai danni del nostro sito: salva questa eventualità, in genere i dati sui contatti web non vengono conservati per più di sette giorni.<br/>
<br/>
<span>• dati forniti volontariamente dall'utente</span><br/>
L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta per sua stessa natura la successiva acquisizione dell'indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella missiva. Specifiche informative sintetiche sono riportate nelle pagine del sito predisposte per particolari servizi a richiesta. Invitiamo i nostri utenti, nelle loro richieste di servizi o nei loro quesiti, a non inviare nominativi o altri dati personali di soggetti terzi che non siano strettamente necessari; ben più opportuno appare, invece, l'uso di nomi di fantasia.<br/>
<br/>
<span>• Cookies</span><br/>
Per Cookie s'intende un elemento testuale che viene inserito nel disco fisso di un computer solo in seguito ad autorizzazione. Se si acconsente, il testo viene scaricato in un file di piccole dimensioni. I Cookies hanno la funzione di snellire l'analisi del traffico sul Web o di segnalare quando un sito specifico viene visitato e consentono alle applicazioni Web di inviare informazioni a singoli utenti. Nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di Cookies per la trasmissione di informazioni di carattere personale, né vengono utilizzati c.d. Cookies di sessione o Cookies persistenti di alcun tipo, ovvero sistemi per il tracciamento degli utenti.<br/>
<br/>
<span>• facoltatività del conferimento dei dati</span><br/>
A parte quanto specificato per i dati di navigazione, l'utente è libero di fornire i dati personali per sollecitare l'invio di materiale informativo o richiedere gli altri tipi di servizi offerti da ditta Simona Gerosa. Il loro mancato conferimento può comportare l'impossibilità di ottenere il servizio richiesto.<br/>
<br/>
<span>• modalità del trattamento</span><br/>
I dati personali sono trattati in formato cartaceo e/o con strumenti automatizzati, per il tempo necessario a conseguire gli scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.</span>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Disclaimer;