import './LaDIAGNOSI.css'
import DescriptionToggle from "../DescriptionToggle/DescriptionToggle";
import React from "react";

function LaDIAGNOSI() {
    const desc1 = <>La luce di Wood, una speciale luce nera che illumina le chiazze, aiuta il dermatologo a confermare
        la diagnosi. <br/>
        <br/>
        Tra le domande più frequenti sulla storia medica del paziente: storia familiare di vitiligo, stress, traumi o
        scottature,se ci sono casi in famiglia capelli grigi in età giovane, se sono presenti altre malattie autoimmuni
        nel paziente o nei familiari. In alcuni casi è utile una biopsia per escludere altre patologie come lupus,
        tinea. Ad alcuni pazienti si raccomanda di sottoporsi ad una visita oculistica per escludere eventuale uveite
        (infiammazione di una parte dell’occhio). <br/>
        <br/>
        La Vitiligine, è una malattia complessa in cui si intrecciano problematiche genetiche, immunitarie,
        endocrinologiche ed altre. Alcune di queste sono indagabili attraverso esami del sangue che lo specialista di
        norma dovrebbe richiedere.<br/>
        <br/>
        Emocromo<br/>
        Formula leucocitaria<br/>
        Ac anti DNA nativo<br/>
        Ac anti ANA<br/>
        Ac anti ENA<br/>
        Ac anti Tireoglobulina<br/>
        Ac anti Tireoperossidasi<br/>
        Ac anti Mucosa gastrica<br/>
        Ac anti Cel. Insul. Pancreatiche<br/>
        Ac anti Muscolo liscio<br/>
        Ac anti Gliadina<br/>
        Emoglobina glicosilata<br/>
        TSH<br/>
        FT3, FT4<br/>
        IgE totali<br/>
        Cortisolo<br/>
        Prolattina</>
    const desc2 = <>È una malattia difficile da curare, i risultati sono lenti e non compaiono in tutti: a volte per
        vedere qualche risultato ci vogliono dai 6 mesi ai due anni.

        Se una terapia non funziona, o non funziona più, si passa ad un’altra: quello che Pearl Grimes chiama
        “rotational therapy”.

        I risultati delle terapie sono diversi da paziente a paziente.</>
    const desc3 = <span className="Testo">La malattia non compromette lo stato di salute fisico, ma le sue ripercussioni sociali e psicologiche sono ben documentate da diversi studi sulla qualità della vita.</span>
    const desc4 = <span className="Testo">Le terapie più in uso sono :steroidi topici, immunomodulatori topici, raggi UVB a banda stretta, laser ad eccimeri, trapianto di melanociti vitamine e minerali come coadiuvanti.</span>
    const desc5 = <span className="Testo">Il sistema immunitario attacca, producendo autoanticorpi, uno o più organi del paziente. Nel caso della vitiligine, il sistema immunitario probabilmente riconosce i melanociti come qualcosa di estraneo, li attacca, indebolendoli o distruggendoli. <br/> Alcune malattie autoimmuni sono quelle della tiroide, l’alopecia areata, il lupus, l’anemia perniciosa, il morbo di Crohn.</span>
    const desc6 = <span className="Testo">Il melanocita è la cellula che produce la melanina (il pigmento).</span>
    const desc7 = <span className="Testo">La melanina è il colore bruno della pelle e dei peli, deriva dall’aminoacido tirosina ed è sintetizzato dai melanociti.</span>
    const desc8 = <span className="Testo">Generalmente, ci si accorge della malattia quando si notano sulla propria pelle le chiazze bianche depigmentate. Anche se la pelle rimane normale, alcuni sperimentano prurito nelle zone che si depigmentano. Le chiazze bianche si notano di più nelle zone scoperte dagli abiti, mani, piedi, braccia, gambe, labbra. La malattia colpisce spesso anche ascelle, bocca, occhi, narici, collo, gomiti, ginocchia, ombelico, genitali. <br/>
            <br/>
            Nella vitiligo focale la depigmentazione è limitata a una o poche zone.
            <br/>
            Nella vitiligo segmentaria le chiazze si manifestano solo su un lato del corpo.
            <br/>
            La più comune è la vitiligo vulgaris, o generalizzata: la depigmentazione avviene su diverse parti del corpo, in forma simmetrica. In alcuni la malattia si manifesta anche con capelli, barba, ciglia  sopracciglia e peli del corpo diventati bianchi, per mancanza di pigmento.</span>
    const desc9 = <span className="Testo">Per ragioni ancora ignote (ghiandole sudoripare, terminazioni nervose) alcune zone del corpo (viso, ascelle, mani, polsi, piedi, gomiti, ginocchia, genitali) sono colpite dalla malattia più frequentemente di altre.</span>
    const desc10 = <span className="Testo">Il decorso varia da paziente a paziente. In molti casi inizia con una piccola chiazza, nel tempo ne appaiono altre, e tutte si possono ingrandire. In alcuni le chiazze rimangono le stesse per anni ed anni, poi improvvisamente possono comparire nuove zone depigmentate. <br/>
            Molti pazienti riferiscono che nel corso della vita alcune chiazze si ripigmentano spontaneamente, senza nessuna terapia. In qualche caso si assiste invece nel giro di uno, due anni a una depigmentazione rapida e completa.</span>
    const desc11 = <span className="Testo">Qualcuno riferisce di sentire prurito nel luogo dove poi appare o dove si sta estendendo una chiazza: non esistono studi, e quindi al momento nessuna spiegazione, per questo fenomeno.</span>
    const desc12 = <span className="Testo">In molti, ma sicuramente non in tutti i casi, la vitiligo tende ad estendersi lentamente nel tempo, e nessuno è in grado di predirne l’evoluzione.
            <br/>
            In alcuni la malattia rimane stabile per anni ed anni, altri assistono ad una veloce estensione delle chiazze in breve tempo.</span>
    const desc13 = <span className="Testo">In questi casi la malattia compare solo su un lato del corpo, in forma limitata.</span>
    const desc14 = <span className="Testo">La ”vitiligo vulgaris” si manifesta in forma simmetrica, cioè bilaterale, vale a dire che compare su entrambi i gomiti, ginocchia, mani, ecc.</span>
    const desc15 = <td class="DomRisp"><span className="Testo"><b>Non è contagiosa.</b>
            <br/>
            Se lo fosse, sarebbe molto più diffusa. Ci sono diverse teorie sulle cause predisponenti, e la ricerca prova che c’è alla base una predisposizione genetica.</span>
    </td>
    const desc16 = <span className="Testo">Si ritiene che derivi dal latino ”vitulus”, vitello, che sta ad indicare il suo mantello pezzato, o da “vitium”, difetto, o mancanza (di pigmentazione).</span>
    const desc17 = <td class="DomRisp"><span className="Testo">Ci sono diverse ipotesi, ma nessuna certezza. I ricercatori concordano nel ritenere che ci sia una predisposizione genetica.
            <br/>
            La malattia può essere il risultato di fattori autoimmuni, virali,stress ossidativo (eccesso di perossido di idrogeno), interazione dei melanociti con il sistema nervoso, fattori autotossici (nella produzione di melanina, sostanze tossiche per i melanociti). C’è la teoria dell’autodistruzione dei melanociti.
            <br/>
            Altri ipotizzano un difetto genetico nei melanociti, che li renderebbe deboli e  più suscettibili. Molti pazienti riferiscono che è scatenata o esacerbata da stress, eventi traumatici, traumi, scottature, ferite.
            <br/>
            Sappiamo che l’insorgenza della malattia, in soggetti predisposti, è dovuta anche all’esposizione ad alcune sostanze chimiche, come i fenoli.</span>
    </td>
    const desc18 = <span className="Testo">Qualche ricercatore ha ipotizzato che alla comparsa o all’estendersi della vitiligo contribuisce lo stress ossidativo, vale a dire un accumulo di perossido di idrogeno(acqua ossigenata) nella pelle. Questo è un processo naturale che avviene in tutti.
            <br/>
            Un enzima, il catalase, trasforma il perossido di idrogeno nella pelle in acqua ed ossigeno. In hi ha la vitiligine invece potrebbe esserci una carenza di catalase nella pelle.</span>
    const desc19 = <span className="Testo">Ad avvalorare la teoria della predisposizione genetica,c’è anche il fatto che in alcuni la malattia è presente in uno o più familiari consanguinei. La predisposizione da sola non basta: per scatenare la malattia giocano un ruolo anche dei “fattori ambientali” scatenanti, che purtroppo al momento non sono noti. Poiché  la ricerca attuale indaga sui geni colpevoli, in futuro saranno disponibili anche terapie geniche. <br/>
            Il prof. Richard Spritz, dell’Università del Colorado,da diversi anni studia la vitiligo dal punto di vista genetico e pubblicherà a breve uno studio con le sue ultime scoperte, che porteranno a nuove cure sia per la vitiligo che per altre malattie autoimmuni. Grazie a questi studi genetici, avremo anche in un prossimo futuro la possibilità di fermare e prevenire la malattia.</span>
    const desc20 = <td class="DomRisp"><span className="Testo">Nel 20% dei casi  la vitiligo è presente in più di un familiare, mentre l’80% dei pazienti riferisce di non avere nessuno in famiglia con la malattia.
            <br/>
            Molti esperti concordano sul fatto che non tutti coloro che sono predisposti alla malattia, la svilupperanno.</span>
    </td>
    const desc21 = <span className="Testo">Alcune sostanze chimiche, in modo particolare i fenoli, l’idrochinone e il monobenziletere di idrochinone possono scatenare la malattia in soggetti predisposti. <br/>
            Tra le aziende in cui si lavora a contatto con queste sostanze, le aziende automobilistiche, l’industria chimica, fotografica, ceramiche.</span>
    const desc22 = <span className="Testo">La vitiligo non porta altre malattie, ma a volte è associata ad endocrinopatie e a malattie autoimmuni, tra cui malattie della tiroide, (30-40%), in modo particolare morbo di Graves e tiroidite di Hashimoto, alopecia areata, lichen planus, orticaria cronica, anemia perniciosa, (1-10%), lupus, diabete mellito, (1-7%), sindrome autoimmune poliglandolare, morbo di Crohn, morbo di Addison(2%).</span>
    const desc23 = <span className="Testo">Vi sono farmaci che passano al feto attraverso la placenta. Anche gli ultravioletti possono comportare qualche rischio. Se aspetti un bambino, è d’obbligo consultare il dermatologo e l’ostetrico per valutare possibili rischi delle terapie.</span>
    const desc24 = <span class="Testo">Come per tutte le malattie su base genetica, il timore di trasmetterla alle generazioni future è grande e può rendere la gravidanza un periodo particolarmente difficile. Prima di decidere di avere dei figli, come per tutte le malattie genetiche ed autoimmuni, è importante che i futuri genitori valutino chiaramente il rischio di trasmettere una malattia.
            <br/>
            I genitori devono essere consci di poter dare amore e supporto ai propri figli ed essere in grado di non gravarli con i loro sensi di ansia e di colpa. Un genitore può compensare i suoi sensi di colpa con l’iperprotezione, o può manifestare meno affetto verso un figlio con la vitiligine. Questi atteggiamenti vengono ben percepiti dai bambini.
            <br/>
            I piccoli che crescono in una famiglia che ha un atteggiamento positivo ed ottimistico verso la malattia crescono bene e non si faranno problemi per la loro diversità. Quando la malattia è presente, tutta la famiglia ne è condizionata ed è un segno di forza che tutti i membri di quella famiglia siano in grado di parlare apertamente delle loro speranze e dei loro timori. La vitiligo è uno tra i molti problemi che possono condizionare la lunga vita di una famiglia. Essere preparati a parlare e ad ascoltare è l’aiuto più grande che si può dare. La vitiligo può unire la famiglia, come può anche dividerla.
            <br/>
            Per qualsiasi bambino è possibile sviluppare la vitiligo, in quanto l’1-2% della popolazione ne soffre. Quando è presente in un genitore, le possibilità aumentano leggermente. Gran parte dei pazienti non hanno in famiglia nessuno con la malattia. Comunque c’è anche chi  ha genitori, figli, fratelli con la vitiligo.</span>
    const desc25 = <span class="Testo">Gli esperti sono quasi tutti concordi nel dire che secondo i dati disponibili non c’è un maggior rischio di cancro  della pelle nei pazienti di vitiligo, sia all’interno delle chiazze che nella pelle normale. Alcuni ritengono che non c’è aumento di rischio per il melanoma, ma un rischio un po’ più elevato per alcuni tipi di carcinoma.
            <br/>
            Per quanto riguarda la psoriasi ci sono dati che confermano un rischio maggiore di cancro della pelle in seguito alle dosi cumulative nel corso degli anni di Puva e UVBnb.
            <br/>
            Alcuni ritengono che l’UVB sia meno pericoloso, ma i dati sono ancora scarsi perché si tratta di terapie relativamente recenti.</span>


    return (
        <>
            <DescriptionToggle description='• Come viene diagnosticata?' link={desc1}/>
            <DescriptionToggle description='• Mi è stato detto che non c’è cura, è vero?' link={desc2}/>
            <DescriptionToggle description='• Alcuni dicono che è un disordine di tipo cosmetico, è vero?'
                               link={desc3}/>
            <DescriptionToggle description='• Quali sono le terapie disponibili?' link={desc4}/>
            <DescriptionToggle description='• Cos’è una malattia autoimmune?' link={desc5}/>
            <DescriptionToggle description='• Cos’è il melanocita?' link={desc6}/>
            <DescriptionToggle description='• Cos’è la melanina?' link={desc7}/>
            <DescriptionToggle description='• Quali sono i sintomi della vitiligine?' link={desc8}/>
            <DescriptionToggle
                description='• Ho delle chiazze di vitiligine alle ascelle, ai piedi e sui genitali. E’ normale?'
                link={desc9}/>
            <DescriptionToggle description='• Come si evolve la malattia?' link={desc10}/>
            <DescriptionToggle description='• La depigmentazione è sempre accompagnata da prurito?' link={desc11}/>
            <DescriptionToggle description='• Le chiazze si estenderanno nel tempo? Diventeranno più grandi?'
                               link={desc12}/>
            <DescriptionToggle description='• Cos’è la vitiligo segmentaria?' link={desc13}/>
            <DescriptionToggle description='• Cos’è la vitiligo generalizzata?' link={desc14}/>
            <DescriptionToggle description='• La vitiligine è contagiosa?' link={desc15}/>
            <DescriptionToggle description='• Da dove viene la parola “vitiligine”?' link={desc16}/>
            <DescriptionToggle description='• Cosa causa la vitiligine?' link={desc17}/>
            <DescriptionToggle description='• Cos’è lo stress ossidativo?' link={desc18}/>
            <DescriptionToggle description='• E’ vero che la genetica gioca un ruolo nella vitiligo?' link={desc19}/>
            <DescriptionToggle description='• Cos’è la suscettibilità genetica?' link={desc20}/>
            <DescriptionToggle
                description='• È vero che l’esposizione a certe sostanze chimiche può causare la vitiligo?'
                link={desc21}/>
            <DescriptionToggle description='• Ci sono altri sintomi, la vitiligo può portare altre malattie?'
                               link={desc22}/>
            <DescriptionToggle description='• Quali terapie devo evitare se aspetto un bambino?' link={desc23}/>
            <DescriptionToggle description='• Aspetto un bambino: erediterà la vitiligine?' link={desc24}/>
            <DescriptionToggle description='• Sono a rischio di cancro?' link={desc25}/>
        </>
    )
}

export default LaDIAGNOSI;