import './LaTuaStoria.css'
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";

function LaTuaStoria(){
    return(
        <div className='storia'>
            <Header/>
            <div className='storia_content'>
                <Menu/>
                <div className='storia_filling'>
                    <div id="mainarea">
                        <div class="catgroupnamearea">
                            <h1 class="heading">La tua storia »  </h1>
                        </div>

                        <div id="maincontarea">
                            <table cellspacing="0" cellpadding="0" width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td bgcolor="#e6f5ff">
                                        <div align="center">
                                            <p class="fasciaTitoli">&nbsp;</p>
                                            <div align="left"><a id="elenco" name="elenco"></a><strongs>&nbsp; &nbsp; Se hai un'esperienza da condividere con chi come te soffre di vitiligine, <br/>
            &nbsp; &nbsp; invia la tua storia a:</strongs> <a href=""><span class="Titolo"><strong>lamiastoria@arivonlus.it</strong></span></a></div>
                                            <div align="left"><span class="Testo"><br/>
            &nbsp; &nbsp; Pubblichiamo tutte   quelle che possono essere utili ad altri con la vitiligine.<br/>
            &nbsp; &nbsp;             Le raccoglieremo   tutte, per presentarle nelle sedi istituzionali appropriate.<br/>
            &nbsp; &nbsp;             Puoi firmare con   le tue iniziali se non desideri che compaia il tuo nome.</span></div>
                                            <p>&nbsp;</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td class="fasciaTitoli">
                                        <p align="left">&nbsp;</p>
                                        <span class="Titolo">
            <p align="left"><strong>
               <a href="#48">•Sono Francesca mamma di Krystal di 5 anni la mia principessa...</a><br/><br/>

                             <a href="#47">•Ciao mi chiamo Giusi e ho 41 anni sono affetta da più di 20 anni di vitiligine …</a><br/>
            <br/>
            <a href="#46">• Ciao a tutti amici miei! Quanto mi rivedo nelle vostre testimonianze, dalla denominazione "cane dalmata" …</a><br/>
            <br/>
            <a href="#45">• Gentili utenti, ho scoperto questo sito cercando di capire come comportarmi con un uomo che mi piace molto …</a><br/>
            <br/>
            <a href="#44">• Ciao sono Agata ho 16 anni e soffro di vitiliggine da quando avevo 13 anni …</a><br/>
            <br/>
            <a href="#43">• Ciao a tutti, sono Antonio, e soffro di vitiligine da quando avevo 50 anni …</a><br/>
            <br/>
            <a href="#42">• Ciao sono Carlo. La mia storia, non so ora qual'è la più importante che potrei raccontate …</a><br/>
            <br/>
            <a href="#41">• Racconto la mia esperienza per condividerla …</a><br/>
            <br/>
            <a href="#40">• Buongiorno a tutti voi! Nonostante soffra di vitiligine da alcuni anni…</a><br/>
            <br/>
            <a href="#35">• Ho 34 anni, sono sposata e ho una bambina di 13 mesi. Soffro di vitiligine da 4 anni …</a><br/>
            <br/>
            <a href="#34">• Per ironia della sorte, mi chiamo Chiara. Anche se sono chiara solo nei punti colpiti dalla vitiligine …</a><br/>
            <br/>
            <a href="#33">• Salve a tutti mi chiamo Daniela ho 35 anni e sono affetta da vitiligine da quando ne avevo circa 20 …</a><br/>
            <br/>
            <a href="#32">• Vitiligine e ricamo, ovvero ' vitiligo and embroidery ' …</a><br/>
            <br/>
            <a href="#31">• Buongiorno, mi chiamo  Antonio, ho 33 anni e da quando ne avevo 16 sono affetto da vitiligine, …<br/>
            </a><br/>
            <a href="#30">• La nostra amica Elena ci sottopone una richiesta davvero interessante…<br/>
            <br/>
            </a></strong></p>
            <p align="left"><strong><a href="#29">• Ciao a tutti, amici macchiati come me...</a></strong></p>
            <p align="left"><strong><a href="#28"><br/>
            • Ciao. Io sono Giulia una ragazza di 20 anni che vive nella provincia di Milano...</a></strong></p>
            <p align="left"><strong ><a href="#27"><br/>
            • Sono una ragazza 25 enne..e a mio fratello…</a></strong></p>
            <p align="left"><strong><a href="#26"><br/>
            • Ciao sono Andrea da Padova, 40 anni single. Ho la vitiligine d 6 anni ...</a></strong></p>
            <p align="left"><strong><a href="#25"><br/>
            • Mio figlio, ora trentaquattrenne, soffre di vitiligine da quando aveva circa 8-9 anni ...</a></strong></p>
            <p align="left"><strong><a href="#24"><br/>
            • Mi chiamo Daniela, ho 37 anni, sposata e sono mamma di Matilde 8 anni ...</a></strong></p>
            <p align="left"><strong><a href="#23"><br/>
            • Mi chiamo Federica,sono una ragazza di 24 anni              ...</a></strong></p>
            <p align="left"><strong><a href="#22"><br/>
            • Lo 'scandaloso' romanzo d'esordio             di Francesca di Mattia Bikbova             ...</a></strong></p>
            <p align="left"><strong><a href="#21"><br/>
            • Mi chiamo Roberta, ho 43 anni e da quando ne avevo  7...</a></strong></p>
            <p align="left"><strong><a href="#20"><br/>
            • Ciao a tutti, sono Giorgia e ho 21 anni ...</a></strong></p>
            <p align="left"><strong><a href="#17"><br/>
            • Grazie Simona, finalmente! ...</a></strong></p>
            <p align="left"><strong><a href="#18"><br/>
            • Carissimi compagni di avventura...</a></strong></p>
            <p align="left"><strong><a href="#13"><br/>
            • Mi chiamo Antonia, ho 38 anni, insegno lettere in un liceo...</a></strong></p>
            <p align="left"><strong><a href="#14"><br/>
            • In un mondo come in quello in cui viviamo...</a></strong></p>
            <p align="left"><strong><a href="#1"><br/>
            • Ecco  brevemente la mia storia...</a></strong></p>
            <p align="left"><strong><a href="#2"><br/>
            • La  vitiligine mi accompagna da sempre...</a></strong></p>
            <p align="left"><strong><a href="#3"><br/>
            • Sono una signora di 58 anni...</a></strong></p>
            <p align="left"><strong><a href="#4"><br/>
            • Sono un ragazzo di 25 anni...</a></strong></p>
            <p align="left"><strong><a href="#5"><br/>
            • Mi chiamo Beatrice, ho 33 anni...</a></strong></p>
            <p align="left"><strong><a href="#6"><br/>
            • Beh io sono Roberto...</a></strong></p>
            <p align="left"><strong><a href="#7"><br/>
            • Mi chiamo Nicoletta Foglio...</a></strong></p>
            <p align="left"><strong><a href="#10"><br/>
            • Mi chiamo Vincenzo ho 26 anni abito a Mazara Del Vallo...</a></strong></p>
            <p align="left"><strong><a href="#11"><br/>
            • CIAO, sono Marina ho 47 anni...</a></strong></p>
            <p align="left"><strong><a href="#21"><br/>
            • Ciao a tutti! Sono una donna di 43 anni...</a></strong></p>
            <p align="left"><strong><a href="#20"><br/>
            • Gentili lettori,io soffro di vitilligine da 1997...<br/>
            </a></strong><a href="#15"><br/>
            • Sono Manuela da &nbsp;Domodossola, ho 34 anni e da quasi 20 ho la  vitiligine</a><br/>
            <br/>
            <a href="#23">• Buongiorno, sono una ragazza di 38 anni ho la vitiligine da 23 anni</a> <br/>
            <br/>
            <a href="#24">• Ciao, mi chiamo Angelica, ho 37 anni e ho la vitiligine</a><br/>
            <br/>
            <a href="#25">• ciao! mi  chiamo camilla,ho 21 anni e ho da poco &nbsp;scoperto di avere la &nbsp;vitiligine.</a><br/>
            <br/>
            <a href="#26">• Solo oggi sono venuta a conoscenza di questa associazione...</a><strong><br/>
            <br/>
            <a href="#27">• Ciao a tutti, sono Carla, ho 20 anni...</a></strong></p>
            </span>
                                        <p align="left"><strong> </strong></p>
                                        <strong><strong> <br/>
                                            <br/>
                                                <p>&nbsp;</p>
                                        </strong></strong></td>
                                </tr>
                                <tr>
                                    <td>
                                        <p></p>
                                        <p>&nbsp;</p>
                                        <p></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p align="justify">&nbsp;</p>
                                        <span class="Testo">
            <p align="justify">Rosanna</p>
            <p align="justify">Brava Rosanna, sei anche un'artista, oltre che una bella giovane signora e  mamma affettuosa ed attenta dei  tuoi due figli. Sei persino riuscita a sdrammatizzare la vitiligine, a sorprenderci e farci sorridere!!</p>
            <p><em><strong> A.D. </strong></em></p>
            </span>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="1632232222222222222" name="48"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Sono Francesca mamma di Krystal di 5 anni la mia principessa...</span></strong></p>
                                        <span class="Testo">
            <p>Sono Francesca mamma di Krystal di 5 anni la mia principessa....ho letto le vostre storie....
l'anno scorso mia figlia ha avuto un inverno burrascoso 2 focolai polmonari la scoperta di esostosi toracica e per debellare il tutto siamo andati in vacanza in tunisia e li ci siamo beccati pure virus vomito dissenteria....e dopo 2 gg la prima macchia vicino la bocca ...inizialmente pensavo fosse perchè sporca di gelato e non si fosse abbronzata poi all'orecchio boh tornata siamo andati dal dermatologo che in due secondi mi ha liquidato con il verdetto "vitiligine".....mi è crollato il mondo....cosa la mia piccola principessa con quelle macchie ...mi sono sentita responsabile io che pensavo di avere una figlia normale di aver fatto la cosa piu bella  mi sento in colpa.....lei è cosi dolce e ipersensibile non so se sarà in grado di farsene una ragione ...e già questo mondo è cattivo e lei è cosi fragile già ora non so come renderla più forte menefreghista .... con la mia bravissima pediatra mi voglio informare per un bravo dermatologo esperto in vitiligine e vi prego se voi conoscete un ospedale dove trattano i bambini fatemelo sapere perche voglio curarla...ho visto a pisa givs...e a genova gaslini voi che avete più esperienza cosa mi consigliate di fare? voi ragazze come mi consigliate di comportarmi da mamma?
</p>
          </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="163223222222222222" name="47"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Ciao  mi chiamo Giusi e ho 41 anni sono affetta da più di 20 anni di vitiligine…</span></strong></p>
                                        <span class="Testo">
            <p>Ciao  mi chiamo Giusi e ho 41 anni sono affetta da più di 20 anni di vitiligine;  questa cara compagna probabilmente è con me da sempre ma, si è manifestata in  concomitanza con esordio diabete insulino dipendente. Da circa un mese ho avuto  diagnosi di ipertiroidismo Basedown. In pratica sto accumulando una bella  collezione di malattie autoimmuni. Mentre diabete e ipertiroidismo sono per  cosi dire “nascoste” la vitiligine si fa sempre più presente. Ho provato  diverse terapie comprese PUVA (lasciata causa distanza da casa e  incompatibilità con il lavoro). Ma adesso vorrei riprovare a seguire una cura,  perché l’effetto dalmata mi inizia a pesare....probabilmente perché un po’ il  mio complesso stato di salute mi pesa parecchio e la vitiligine essendo più  evidente è quella che vorrei riuscire a camuffare....a proposito dove si  acquistano i prodotti di camouflage? potete consigliarmi qualche prodotto .<br/>
            Chiedo  se qualcuno ha provato delle terapie valide con almeno qualche risultato  incoraggiante. Cosa ne pensate del metodo Bioskin Evolution? <br/>
            Vi  ringrazio per la vostra attenzione e soprattutto per il vostro lavoro di  supporto e informazioni a noi affetti da questa malattia.<br/>
            Saluti  Cordiali<br/>
            Giuseppa  Sedan<br/>
            Dozza  (BO)</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="16322322222222222" name="46"></a>/
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Ciao a tutti amici miei! Quanto mi rivedo nelle vostre testimonianze, dalla denominazione "cane dalmata"…</span></strong></p>
                                        <span class="Testo">
            <p>Ciao a  tutti amici miei! Quanto mi rivedo nelle vostre testimonianze, dalla  denominazione "cane dalmata" ad alcuni problemi psicologici che  necessariamente queste malattia comporta.. Ho notato i primi segni di  vitiligine (chiazze sul petto con capezzoli rosa e dietro la  schiena)&nbsp;all'età di 24 anni, a causa di un forte stress derivante da un  esame universitario. Adesso ho 28 anni, con l'aiuto della fototerapia -  coadiuvata dalla crema piperina - e dal sole, piano piano le chiazze sul petto  stanno scomparendo; dietro la schiena e sul linguine invece persiste anche si  si sta restringendo. La prima estate anche per me è stata la più dura, evitai  di andare al mare per paura che le macchie si evidenziassero. Poi parlando con  una mia cara parente (che fino all'età di 16 anni era affetta da questa  malattia su tutto il corpo) ho iniziato a godermi il sole ed il mare  fragandomene degli sguardi delle persone&nbsp;anche se&nbsp;alle volte è  davvero difficile. Come in tutte le cose cerco di guardare sermpre le cose dal  lato positivo, e devo dire che comunque questa malattia per certi versi mi ha reso  più forte. Ovviamente cercherò di combatterla sempre, con l'aiuto della crema  piperina, del SOLE (naturale, con lettini solari, e fototerapie speciali) e gli  integratori di beta carotene per contrastare l'effetto "age". Forza e  coraggio!</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="1632232222222222" name="45"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Gentili utenti, ho scoperto questo sito cercando di capire come comportarmi con un uomo che mi piace molto…</span></strong></p>
                                        <span class="Testo">
            <p>Gentili utenti,<br/>
            Ho scoperto questo sito cercando di capire come  comportarmi con un uomo che mi piace molto, affetto da vitiligine. Non so  quanto essa sia diffusa, ho notato che sul volto usa del correttore e le  braccia, malgrado l'estate caldissima, sono sempre coperte dalle maniche della  camicia allacciate, le mani sono un po' macchiate ma conosco persone con  macchie ben più estese.<br/>
            Si tratta di una persona interessantissima e  brillantissima che ho conosciuto da poco, ma al contempo, da un certo punto di  vista mi pare molto chiusa. Ci ho pensato un po' su e penso che si tratti di un  "blocco" dato dalla vitiligine.<br/>
            Vorrei rassicurarlo a tal proposito, ma non so come, non  vorrei metterlo in imbarazzo.<br/>
            Pensate che non me ne ero nemmeno accorta di queste  macchie quando l'ho conosciuto talmente mi aveva affascinata e divertita; me le  ha fatte notare un'amica comune ... <br/>
            Io non so come ci si possa sentire, ma penso che più peso  venga data alla cosa e più quest'aspetto venga percepito all'esterno.<br/>
            C'è una mia vicina di spiaggia, una bellissima ragazza  mora mora, madre di tre splendidi ragazzini e moglie di un gran bel uomo,  ebbene lei si fa tutta l'estate, tutti i giorni, al mare, abbronzatissima e  sempre con shorts e top scollatissimi... Alla faccia della vitiligine! Alla  fine nessuno ci fa caso Grazie Un abbraccio a tutti.</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="163223222222222" name="44"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Ciao sono Agata ho 16 anni e soffro di vitiliggine da quando avevo 13 anni…</span></strong></p>
                                        <span class="Testo">
            <p>Ciao sono Agata ho 16 anni e soffro di vitiliggine da  quando avevo 13 anni. Tutto è partito da una piccola macchia sul collo che ha  cominciato ad allargarsi poi ne sono comparse anche sulla schiena e sul  décolleté .Adesso ho la schiena dove nella parte superiore ci sono tre grandi  macchie bianche , mentre nella parte inferiore ne stanno venendo fuori delle  altre. Sul viso sono comparse l'anno scorso da una macchia in fronte e poi si  sono allargate e , mi sono cresciuti anche due tre capelli bianchi. L 'inizio  dell'anno scolastico quando cambi scuola è un continuo che i tuoi compagni ti  chiedono che cosa hai , perché , che cosa è , ed è fastidioso. I miei genitori  mi porteranno forse a fare le lampade apposta. Spero di migliorare, ma a volte  mi piacerebbe poter parlare con persone che hanno i miei stessi problemi e che  mi possono capire . Un giorno ho provato a parlarne con le mie amiche ma come i  miei genitori hanno detto che non è una cosa tanto grave e che non c'è da  vergognarsi, ma non così facile.</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="16322322222222" name="43"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Ciao a tutti, sono Antonio, e soffro di vitiligine da quando avevo 50 anni…</span><br/>
                                        </strong></p>
                                        <span class="Testo">
            <p>Ciao a tutti, sono Antonio, e soffro di vitiligine da  quando avevo 50 anni, adesso ne ho 59,le prime macchioline sono comparse nelle  dita e in fronte, adesso ho anche il viso a macchie di leopardo, i dermatologi  pare non ci capiscano niente, sono buoni soltanto a prescriverti delle creme  che poi non servono a niente tranne a svuotare le nostre tasche! Per loro è'  soltanto un problema estetico, non capiscono i disagi che uno prova a livello  psicologico...</p>
            <p>.</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="1632232222222" name="42"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Ciao sono Carlo. La mia storia, non so ora qual'è la più importante che potrei raccontate…</span><br/>
                                        </strong></p>
                                        <span class="Testo">
            <p>Ciao sono Carlo. La mia storia, non so ora qual'è la più  importante che potrei raccontate. &nbsp;<br/>
            Ho sempre detto che nella vita ci vuole solo e solo  Fortuna.&nbsp;<br/>
            Come può essere accettato che un giorno di primavera al  risveglio, &nbsp;mentre che ti lavi, ti &nbsp;guardi allo specchio, &nbsp;e  vedi &nbsp;che qualcosa in te e cambiato per sempre. Forse è meglio sparire,  cancellarti faresti poco male a chi ti vuole bene. Non riesco a comprendere  perché a me. Chi potrebbe aiutarmi a capire a conviverci... a guarire... ma  cos'è successo per meritarsi questa maledizione. I professori, i scienziati,  &nbsp;gli universitari, &nbsp;ma loro cosa dicono, come pensano di poterci  aiutare... Mi potete rispondere.&nbsp;<br/>
            Grazie<br/>
            Carlo C.</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="163223222222" name="41"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Racconto la mia esperienza  per condividerla…</span><br/>
                                        </strong></p>
                                        <span class="Testo">
            <p>Racconto la mia esperienza  per condividerla. Ho 48 anni, la vitiligine è iniziata verso i 20 anni. Stesso  trascorso di quanti hanno scritto qui. <br/>
            Tre da tre mesi ho iniziato quasi per scherzo la paleo dieta, su consiglio di  un amico, per vedere se riuscivo a normalizzare la pressione arteriosa. In  pratica ho eliminato tutti i cereali e i legumi mentre mi sono tenuto latte  crudo e formaggio. <br/>
            Il primo mese non ho preso la cosa seriamente, comunque ho ridotto di molto  pasta, pizza e alimenti industrializzati. Vedendo mio malgrado un piccolo  miglioramento ho deciso di provare seriamente questo regime alimentare basato  su pesce, carne, frutta e verdura. Al terzo mese la pressione minima è arrivata  a 80 da 95 di tre mesi fa, colesterolo sparito assieme alla cefalea, rigurgito  esofageo e altre amenità. La settimana scorsa ho notato che le mie candide mani  si stanno pigmentando assieme alle altre sparse ovunque. Ho trovato esperienze  simili nei blog in lingua spagnola e inglese nulla in italiano. La maggior  parte degli affetti da vitiligine migliorano, una parte minoritaria non ha  nessun beneficio. Ho letto quanto possibile sulla relazione tra le nuove  varietà di frumento ricavate da ibridazione e modificate geneticamente e  malattie autoimmuni. A onor del vero veri studi non ce ne sono in questa  direzione. Provare non costa nulla. Fatemi sapere....<br/>
            Berto</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="16322322222" name="40"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Buongiorno a tutti voi! Nonostante soffra di vitiligine da alcuni anni …</span><br/>
                                        </strong></p>
                                        <span class="Testo">
            <p>Buongiorno  a tutti voi! Nonostante soffra di vitiligine da alcuni anni, mi sono sempre  affidata al giudizio dei medici e solo ora ho "scoperto" la Vostra  associazione (oltretutto con sede vicinissima al mio paese!). <br/>
            Leggendo  sul sito dei legami tra vitiligine ed eventi stressanti o dolorosi, non posso  che darvene una conferma, essendo la mia esperienza davvero peculiare. Nell'  agosto 2001, per una banalissima scivolata e conseguente caduta, mi sono  fratturata una vertebra. Ero al mare e dunque molto abbronzata.&nbsp;Trascorse  poche ore &nbsp;dalla caduta, già il medico del Pronto Soccorso mi faceva  notare la comparsa di una chiazza bianca dulla mano sinistra e la diagnosticava  cone vitiligine. Insomma prima della caduta non avevo mai avuto nessuna  manifestazione e nel giro di pochissimo tempo ecco&nbsp; apparire la prima  macchia. Non penso sia stata una coincidenza e mi sembra confermare  assolutamente quanto a volte si sostiene sul legame vitiligine-forte dolore. <br/>
            Negli  anni il problema si è aggravato, il Protopic ha dato scarsi risultati e il  medico l'ha infine sospeso (per i sospetti effetti tumorali). Ora sono in  stand-by, spero che approfondendo l'argomento, anche&nbsp;con l'aiuto del  vostro sito, possa trovare una soluzione, anche parziale.<br/>
            Grazie  per la vostra bella e utilissima iniziativa, mi sento meno  "macchiata"!</p>
            Simona            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="1632232222" name="35"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Ho 34 anni, sono sposata e ho una bambina di 13 mesi. Soffro di vitiligine da 4 anni …</span><br/>
                                        </strong></p>
                                        <span class="Testo">
            <p>E' successo tutto in una  notte, già...qualche mese prima di sposarmi sono andata a letto senza  vitiligine e mi son svegliata la mattina dopo con la prima macchia perilabiale.<br/>
            Appena ho visto quella macchia mi son chiesta " Ma cosa ho toccato? Mi è  venuta un'infezione". <br/>
            Passavano i giorni e la macchia non spariva, anzi si  ingrandiva, allora sono andata dalla dermatologa convinta di risolvere al più  presto, perchè mi dovevo sposare, come potevo sposarmi con una macchia sul  viso?<br/>
            Dalla dermatologa ho avuto il responso di vitiligine e ricordo quella frase  lapidaria "non si può curare" e un brivido freddo lungo la schiena,  "non si può curare, anzi, si aspetti che gliene escano altre e altre  ancora"...come non si può curare? Altre macchie? Ma cos'è questa  vitiligine, ma io pensavo che ci si nascesse con la vitiligine, non che venisse  così, in una notte....ma come, io mi devo sposare mica posso andare con questa  macchia, e il trucco, e le foto, e il filmino.....<br/>
            <br/>
            E invece così fu, sono arrivata all'altare, in quello che doveva essere il  giorno più bello della mia vita, col viso deturpato dalla mia prima macchia di  vitiligine e ho passato tutto il viaggio di nozze nell'incubo più atroce, con  altre macchie che mi uscivano e io che mi guardavo continuamente allo specchio  e piangevo, chiedendomi il perchè di tale maledizione. <br/>
            Credo di aver pianto  ormai TUTTE le mie lacrime, perchè non riesco neanche più a piangere per la  vitiligine.<br/>
            Si, la vitiligine è una maledizione, una malattia che ti fa morire dentro, che  cambia la tua vita in un attimo, che aggredisce e deturpa i punti del tuo corpo  più visibili, e quelle macchie maledette come escono in un attimo poi ci  mettono mesi o anni a ripigmentarsi. <br/>
            E' una malattia che cambia i rapporti  interpersonali, che ti fa chiudere, che ti fa sentire solo, che ti fa vedere un  mostro, e più tenti di nascondere quelle macchie e più le creme colorate o coprenti  le evidenziano. <br/>
            Ti senti costantemente sotto gli occhi di tutti, e quando vedi una persona che  non ti ha conosciuta con la vitiligine, che non vedi da tanto, SAI che PRIMA O  POI&nbsp; arriverà&nbsp; LA DOMANDA " Ma cosa hai fatto? Cosa sono quelle  macchie?...Mi dispiace, che peccato...oh ERI una così bella ragazza" <br/>
            E' inutile cercare di conviverci con serenità, gli altri ti mettono davanti in  continuazione quello che hai, se non parlano i loro sguardi insistenti parlano  per loro, se parlano ti chiedono e se ti chiedono ti feriscono con la loro  ignoranza.<br/>
            E non parliamo delle mamme dei bambini coetanei di mia figlia che mi guardano  male, e se tocco qualche loro bambino storcono il naso...e l'umiliazione di  spiegare che NON SONO CONTAGIOSA...e l'umiliazione di sentirsi dire che anche  se non sono contagiosa SA DI SPORCO e DI POCO ORDINATO.<br/>
            E la sofferenza di non credere a mio marito, ogni volta che mi dice che per lui  sarò sempre bellissima bellissima, il desiderio sessuale quasi annientato dal  fatto che IO&nbsp; mi vedo un mostro.E il terrore che un giorno possa averla  anche mia figlia....<br/>
            Questo è solo un assaggio di come quella maledetta notte la vitiligine mi ha  gettata dentro un incubo che non ha fine e io PREGO&nbsp; tutti i ricercatori  di aiutarci, vi prego con il cuore, perchè la vitiligine non è solo una  malattia estetica. Io ad esempio ho scoperto di avere anche un'altra malattia  autoimmune, la Tiroidite di Hashimoto e così mia madre e mia sorella . <br/>
            Io prego  tutti i giorni perchè a loro non succeda questo strazio, perchè a loro non  venga la vitiligine.<br/>
            Io prego tutti i medici, i ricercatori, di fare il possibile per curare questa  malattia maledetta che non ti ammazza fuori, ma che ti ammazza dentro giorno  dopo giorno.<br/>
            <br/>
            Grazie a tutti, e un abbraccio di cuore a tutti i malati di vitiligine</p>
            </span></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td><strong><a id="163223222" name="34"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">•Per  ironia della sorte, mi chiamo Chiara. Anche se sono chiara solo nei punti  colpiti dalla vitiligine …</span><br/>
                                        </strong></p>
                                        <p>&nbsp;</p>
                                        <p>&nbsp;</p>
                                        <span class="Testo">
            <p>Per  ironia della sorte, mi chiamo Chiara. Anche se sono chiara solo nei punti  colpiti dalla vitiligine: il resto, in estate, è pura nutella. Che con il rosa  intenso delle macchie fa di me una coppa di gelato cioccolato &amp; fragola.</p>
            <p>Ormai  ho quasi sessant’anni, convivo con la vitiligine da circa mezzo secolo; abbiamo  trovato un precario modus vivendi che ci permette di sopportarci, io ho smesso  di tormentarla con cure aggressive e lei evita di espandersi esageratamente.</p>
            <p>Ci  sono stati giorni dolorosi, in gioventù, quando ancora non sapevo che la pelle  chiara è delicata: e mi stendevo al sole, allegra e senza protezione,  rialzandomi a sera con la mia solita abbronzatura sul corpo e con le nuove  vesciche pulsanti e infuocate intorno alla bocca. E le lacrime erano insieme di  rabbia, di sofferenza e di sconforto. &nbsp;</p>
            <p>E  ci sono stati giorni di speranza, quando ho iniziato una terapia chimica a base  di pillole e di sole: non ha funzionato, e i miei reni ne hanno sofferto. E  ancora, quando ho saputo di un’innovativa cura a base di raggi UVA: per mesi ho  preso permessi sul lavoro, ho viaggiato su treni pendolari, mi sono fatta  irradiare e mi sono procurata vescichette volontarie sotto alle quali avrebbe  dovuto nascere la nuova pelle colorata. Così diceva, al centro di cura, il  “dottore” che ho poi scoperto essere soltanto l’ingegnere inventore della  macchina. Ho speso un mucchio di soldini, però un guadagno l’ho avuto: le rughe  indelebili incise nella pelle che già a quarant’anni facevano di me una  vegliarda.</p>
            <p>Oggi  siamo in fase di armistizio, io e lei: io mi informo e benedico ARIV e la  ricerca, lei vivacchia e si manifesta con garbo, quasi discreta: guadagna solo  qualche millimetro all’anno, però sceglie le zone più visibili, la carognetta.</p>
            <p>In  attesa di firmare il trattato di pace uso protezioni altissime e, quando serve,  il camouflage: attività nella quale mi sento pronta per l’Oscar alla velocità.</p>
            <p>Ho  una vita bellissima, piena di persone e di meraviglie. La stessa che auguro a  tutti voi, a ognuno nella sua unicità. &nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p>ChL</p>
            </span>
                                        <p>&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td><strong><a id="16322322" name="33"></a><br/>
                                        <br/>
                                    </strong>
                                        <p><strong><span class="Titolo">• Salve a tutti mi chiamo  Daniela ho 35 anni e sono affetta da vitiligine da quando ne avevo circa 20             …</span>
                                        </strong></p>
                                        <p>&nbsp;</p>
                                        <span class="Testo">
            <p>Inizialmente le macchi erano su  alcune dita delle mani ora ne ho sul viso attorno agli occhi, sulla fronte e  sul mento, senza contare i gomiti e da quest'anno new entry nell'interno cosce.  Inizialmente non ci avevo fatto caso ma quando mi sono apparse in viso non ci  ho visto piu'. Non mi volevo rassegnare. Ho provato le lampade UVB che pero'  non hanno avuto un grande effetto. <br/>
            Ma ho anche una grande fortuna: sono  molto chiara di carnagione e quindi d'inverno non si vede nulla e d'estate si  vede qualcosina ma la mia pelle (che per anni ho detestato) non si abbronza  molto e quindi le macchie sono anche molto facili da coprire.</p>
            <p>Penso però che per chi ha una pelle  scura la vita non sia affatto facile e che veramente questa malattia porti a  far chiudere le persone desiderando qualsiasi altra malattia piuttosto che  questa. <br/>
            Spero veramente che la ricerca  riesca a dare delle risposte e nel frattempo chiedo a tutti di guardare avanti  con fiducia e di non isolarsi in una malattia puramente estetica. Se una  persona ci vuole bene amerà anche le nostre macchie</p>
            Daniela</span>          <strong>          </strong></td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><a id="1632232" name="32"></a></strong></p>
                                        <p>&nbsp;</p>
                                        <p><strong><span class="Titolo">• Vitiligine e ricamo, ovvero  vitiligo and embroidery'   …</span><span class="Titolo"><br/>
            </span><br/>
                                        </strong></p>
                                        <p><span class="Testo">Seconda Figlia ha la vitiligine, diagnosticata da nemmeno due mesi. E' una malattia autoimmune, genetica e imprevedibile: non è chiaro come venga, né che decorso possa avere. Chi ne è affetto si può ritrovare coperto di macchie bianche all'improvviso; queste macchie possono espandersi, restare così o retrocedere (raramente).<br/>
            <br/>
            <em>Second Daughter has been diagnosed with vitiligo. It is an autoimmune, genetic and unpredictable disease: it is not clear why it appears nor how it can develop. White spots suddenly show on the skin, they can expand, stay the same or reduce (rarely). </em></span><br/>
                                            <br/>
                                                            <br/>
            <span class="Testo">Mi hanno detto che si chiama 'Arte terapia' <br/>
            <em>I was told it is called 'Art therapy'</em> <br/>
            <br/>
            Non esiste cura per questa malattia, e per quanto non sia contagiosa, pericolosa o mortale, può avere effetti psicologici devastanti. Immaginate un po' come io mi sia sentita quando la mia bellissima diciassettenne ha avuto conferma della diagnosi. Ho avuto dei sensi di colpa travolgenti (quale madre non ne avrebbe avuti), avrei voluto esserne colpita io invece che lei. <br/>
            <br/>
            <em>There is no cure for this disease, it is not contagious, dangerous or life threatening but can have devastating psychological effects. Imagine how I felt when the diagnose was confirmed to my very handsome 17 years old daughter. I felt so guilty  (which mother would have not), I wished I had it instead of her. </em><br/>
            <br/>
            Avevo bisogno ritrovare l'ottimismo ed esprimere il mio dolore in qualcosa di bello, di prezioso, di unico, di incanalare in qualche modo la mia energia creativa. Il binomio pelle-ricamo mi è parso perfetto. <br/>
            <em><br/>
            I needed to gain back my optimism and express my pain in something beautiful, precious, unique; in some way to channel my creative energy. The biniomial skin-embroidery seemed perfect.</em></span><br/>
                                                    <br/>
                                                        <br/>
            <span class="Testo">Particolare <br/>
            <em>Detail</em> <br/>
            <br/>
            Premetto che non ho mai ricamato e chi lo sa fare se ne renderà facilmente conto. Anche la foto non rende giustizia a qualcosa che se non è un'opera d'arte, per lo meno è l'espressione di un passaggio dal tormento all'accettazione. <br/>
            <br/>
            <em>Before you say it, I didn’t embroider before (and the embroiderers within you will easily notice how imperfect my attempt is). The picture too is not well done, but at least it gives an idea of something that doesn’t want to be a work of art, but the expression of my passage from torment to acceptance. </em><br/>
            <br/>
            La macchia di vitiligine non poteva che essere un fiore, bello, delicato, armonioso che ben si inserisse nel resto. Volevo trasmettere a mia figlia l’incoraggiamento ad accettare la malattia come parte di lei, e non come un nemico da combattere. <br/>
            <br/>
            <em>The vitiligo spot couldn’t be anything else but a flower, beautiful, delicate and harmonius with the rest. I wanted to transmit to Second Daughter the encouragement to accept the disease as part of her, and not consider it like an ennemy to fight.</em></span><em><br/>
                                                            <br/>
            <span class="Testo">Il fiore della vitiligine <br/>
            <em>The flower of vitiligo<br/>
            </em></span><br/>
                                                                    <br/>
                                                        </em></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><a id="163223" name="31"></a></strong></p>
                                        <p>&nbsp;</p>
                                        <p><strong><span class="Titolo">• Buongiorno, mi chiamo  Antonio, ho 33 anni e da quando ne avevo 16 sono affetto da vitiligine …</span><br/>
                                            <br/>
                                        </strong></p>
                                        <p><span class="Testo">p</span><span class="Testo">roblema che mi ha molto condizionato nel corso degli anni, soprattutto nel  momento della sua comparsa, comparendomi nella piena fase adolescenziale. Ormai  ci convivo piuttosto bene, ma di sicuro a livello psicologico ed umorale ha  lasciato dentro di me un segno indelebile. Proprio per questo ho deciso di  voler testimoniare la mia esperienza attraverso un libro autoprodotto, "Come  Michael Jackson?", agganciandomi all'ipotesi che la vitiligine fosse alla base  della decisione della star americana di sbiancarsi la pelle, nella speranza che  possa servire per informare le persone sull'argomento, ma soprattutto  rappresentare motivo di conforto e condivisione per chi ne è affetto, o per  chiunque soffra di qualsiasi altro problema simile o che possa richiamare ad  esso. Se vi può interessare il testo è scaricabile gratuitamente al link: <a href="" target="_blank">http://www. lulu.com/product/ebook/come-michael-jackson/11064106  Saluti   Antonio.<br/>
            <br/>
            <br/>
            </a></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" class="fasciaTitoli">
                                        <div align="right"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><a id="163222" name="30"></a><br/>
                                            <br/>
                                                <span class="Titolo">• La nostra amica Elena ci sottopone una richiesta davvero interessante...</span><br/>
                                                <br/>
                                        </strong></p>
                                        <p><span class="Testo"><em><em>Qualcuno di Voi sicuramente conosce o meglio abita, in località di mare con  le caratteristiche come meglio espresse nella mail di Elena che leggerete di  seguito. </em><br/>
            <br/>
            <em>Prego tutti voi di dare il Vostro contributo segnalandoci queste località  all’indirizzo <a moz-do-not-send="true" href="">direttivo@arivonlus.it</a> in modo da creare una mappa di questi posti di mare idonei anche per noi: </em><br/>
            <br/>
            <em><u>da Elena 10.05.2010</u></em><br/>
            <em>Sono a presentarvi una richiesta probabilmente insolita ma credo di grande  utilità per tutti i "vitiliginosi" che come me hanno superato il 50%  delle zone del corpo senza melanina. Adoro il mare e privarmi di questo  elemento è davvero terribile. Sarebbe fantastico poter pianificare le proprie  vacanze con un elenco sottomano delle spiagge più ombrose e alberate dei ns  litorali!! Poi ho pensato ad ARIV, sarebbe un servizio interessante da  offrirci...magari con un blog dedicato... Speranzosa saluto cordialmente </em></em></span><br/>
                                            <br/>
                                                <br/>
                                                    <strong><br/>
                                                    </strong><span class="SottoTitolo"><span class="Titolo"><strong>Carissimi, leggo soltanto oggi la posta, tra le quali trovo questa richiesta di suggerimenti...</strong></span></span><br/>
            <span class="Testo">Anche io adoro il mare e non posso privarmene,  malgrado la vitiligine.<br/>
            All’amica Elena, essendo io siciliana, suggerisco la costa  estremo sud della Sicilia, precisamente per intenderci i luoghi di Montalbano  ovvero le spiagge <br/>
            tra Pozzallo(RG) e Pachino(SR), ricche di dune sulle quali  crescono boschi di eucalipto. Sarò campanilista ma i luoghi valgono veramente  la pena di essere visitati.<br/>
            E poi c’è tutto un percorso turistico che comprende Ragusa  Ibla, Scicli, Modica, Noto, Avola, Siracusa ecc. Insomma una vacanza nella  parte più estrema della Sicilia<br/>
            È indimenticabile. Io vivo a Palermo e ogni volta che posso  mi faccio 300Km per fare la turista e scoprire sempre qualcosa di nuovo. <br/>
            Durante le mie vacanze estive cambio ogni giorno posto in  spiaggia, il litorale è talmente bello che fermarsi in un solo posto sembra  peccato. Piazzo il mio meraviglioso ombrellone<br/>
            E relativa sedia sdraio e non mi muovo finchè sono stufa. Il  mare sembra una piscina e anche chi non ama l’acqua alta è accontentato da  meravigliose secche che degradano dolcemente<br/>
            In acque poco profonde. La gente del luogo non ama sdraiarsi  al sole, ma fare lunghe passeggiate avanti e indietro con i piedi affondati  nella secca: hanno delle abbronzature spettacolari.<br/>
            Io non mi privo di questo piacere per le gambe e mi sono  attrezzata di un ombrellone piccolo, che si trova in tutti i grandi magazzini,  leggero e pratico con il quale vado<br/>
            Allegramente su e giù, cappellino in testa, crema  protettrice e via.<br/>
            Credimi Elena è l’unico posto dove non mi vergogno di stare  in costume e fare il bagno, io ho la vitiligine anche in viso e mi trucco anche  d’estate, le spiagge sono talmente grandi <br/>
            Che puoi anche metterti da sola. Io ho una foto fatta a  ferragosto dove è ritratto il vuoto intorno a me. Ti suggerisco di visitare i  siti internet dei B&amp;B della provincia di Ragusa e Siracusa. <br/>
            Poi da quei siti lasciati guidare nei link così ti fai  un’idea. Se dovessi avere bisogno di aiuto la mia mail è <a href="">sabrina.basso@fastwebnet.it</a>.<br/>
            Saluti per tutti<br/>
            <em>Sabrina  Basso<br/>
            </em></span></p>
                                        <p>&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><a id="16322" name="29"></a></strong></p>
                                        <p>&nbsp;</p>
                                        <p><strong><span class="Titolo">• Ciao a tutti, amici macchiati come me...</span><br/>
                                            <br/>
                                        </strong></p>
                                        <p><span class="Testo">mi piace prenderla  scherzosamente!!!! <br/>
            Ho quasi 40 anni e mi chiamo  Michela....ho la vitiligine da quando avevo 12 anni. Mi è comparsa dopo aver  vissuto un forte stress psicologico ed emotivo. Sono comunque sempre stata una  persona mega attiva, dinamica ma anche tanto emotiva .Le mie macchie  fortunatamente non sono mai state un grosso problema nella mia vita perchè  fortunatamente la natura mi ha regalato un corpo senza tanti altri problemi  fisici e questo è stato per me un grosso aiuto psicologico.&nbsp; <br/>
            Ho comunque cercato di seguire le  cure mediche che mi consigliavano ma senza grossi risultati, quindi&nbsp;mi  sono affidata al trucco. Care amiche e amici cosa devo dirvi, che tutto sommato  non è un grosso problema anche perchè se ci guardiamo intorno le malattie gravi  sono ben altre quindi, sorridiamo e trucchiamoci un po'. <br/>
            Michela<br/>
            <br/>
            <br/>
            </span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" align="right" class="fasciaTitoli">
                                        <div align="right"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a>&nbsp;</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><a id="1632" name="28"></a></strong></p>
                                        <p>&nbsp;</p>
                                        <p><strong><span class="Titolo">• Ciao. Io sono Giulia una ragazza di 20 anni che vive<br/>
            nella provincia di Milano…</span><br/>
                                        </strong></p>
                                        <p>&nbsp;</p>
                                        <span class="Testo">
            <p>Ciao. Io sono Giulia una ragazza di 20 anni che vive nella  provincia di Milano e Soffro di vitiligine dai tempi delle scuole elementari.<br/>
            Da una piccola macchietta bianca sul fianco, ora mi ritrovo  con tutto il corpo coperto da macchie.<br/>
            È da molto tempo che avrei voluto scrivere&nbsp; ma non ho mai trovato il coraggio per  farlo&nbsp; perché avrei moltissimo da dire di  me e di questa mia vita “macchiata” .<br/>
            La malattia mi è stata diagnostica da piccola ; per questo  ancora ringrazio perché in quegli anni ancora non capivo cosa mi stava  succedendo e quindi ho affrontato la mia vita in modo più naturale e “normale”  possibile: e così è stato! Non dico che è stato facile, ma inizialmente non mi  pesava…ero più piccola e quindi molto più distaccata da questa malattia.<br/>
            Ovviamente crescendo tutto è cambiato: soprattutto con  l’inizio delle scuole medie e successivamente superiori.<br/>
            Durante l’inverno non ho mai avuto grandi problemi, a parte  quando mi guardavo allo specchio a casa mia .<br/>
            Che sensazione strana….nessuno avrebbe potuto capirmi. Ero  diversa e non capivo perché tutto questo succedeva a me.<br/>
            Ovviamente i problemi più gravi arrivavano con l’avvento  dell’estate, quindi quando si andava a scuola in magliettine e pantaloncini: da  li è iniziata la mia sofferenza più grande.<br/>
            Quanti soprannomi ho dovuto sopportare, ma la cosa che  odiavo e che sinceramente odio tutt’ora è chi mi chiede : “ma cosa hai fatto?”  “è contagiosa?” “ti andrà mai via?”….e li crollavo.<br/>
            Odio dover spiegare ogni volta che non sono contagiosa e che  questo è uno stupido problema SOLO estetico e che all’apparanza può sembrare  orribile, ma che non porta dolore fisico ma solo psicologico.<br/>
            Sapevo benissimo che questa malattia mi avrebbe accompagnato  per la vita…e quindi che avrei dovuto sopportare tutto questo per sempre.<br/>
            L’estate è tragica per me. Vorrei non arrivasse mai. <br/>
            Tutti gli amici vanno al mare, sognano di abbronzarsi il più  possibile, di stare in spiaggia in costume e uscire in minigonna la sera.<br/>
            E tutto questo io non lo faccio, non ho il coraggio di  farlo: ne ho vergogna. Mi fa più male non essere come le mie amiche, e quindi  di poter fare e vestirmi come voglio senza sentirmi giudicata e guardata male  da qualcuno.<br/>
            Io…quella Giulia che ama abbronzarsi, che da piccola  diventava abbronzatissima, che adora le minigonne e i vestitini cortissimi, che  adora mettersi in costume ,che adora sentirsi bella e che adora da impazzire il  mare.<br/>
            Questa è la mia vita. Ci sono momenti in cui mi sento bene  con me stessa perché nonostante tutto mi vedo “bella” e momenti in cui sprofondo  nella mia tristezza e nella mia solitudine.<br/>
            Quante lacrime ho versato e verso tutt’ora , anche scrivendo  questa lettera, perché vorrei svegliarmi domani ed essere la Giulia che ero  prima.<br/>
            Così passo le mie giornate non pensandoci….cercando di coprirmi  il più possibile anche se fuori ci sono 40 Gradi!!!<br/>
            Ringrazio Dio di avere una famiglia come la mia: con dei  genitori che mi sostengono per quello che sono e che mi vedono come la ragazza  più attraente del mondo, con una sorella stupenda che mi è sempre stata vicina  anche se non ho mai parlato di questo problema&nbsp;  faccia a faccia con lei ma che è la mia vita, con delle amiche stupende,  che non mi fanno pesare questo problema e con il mio ragazzo che mi ama per quello  che sono e che mi è vicino sempre, che io sia completamente vestita o che sia  in costume al mare.<br/>
            Loro credono in me, come tutti i nostri familiari, perché  tengono a noi per ciò che siamo dentro e non per quello che siamo  all’apparenza.<br/>
            Spero nella medicina, perché spero con tutto il cuore che mi  faccia guarire, migliorando la mia vita.<br/>
            Nel mondo c’è molto peggio di questo, persone che soffrono  davvero e non per uno stupido problema estetico&nbsp;  che però porta un peso enorme nel cuore di chi la vive.<br/>
            Io ho deciso di fare il mio massimo per vivere al meglio e  per essere come VOGLIO essere.</p>
            <p>Giulia</p>
            </span>
                                        <p>&nbsp;</p>
                                        <p align="justify">&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" class="fasciaTitoli">
                                        <div align="right"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a>&nbsp;</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><a id="163" name="27"></a></strong></p>
                                        <p><br/>
                                            &nbsp;</p>
                                        <p><strong><span class="Titolo">• Sono una ragazza 25 enne..e a mio fratello…</span><br/>
                                            <br/>
                                        </strong></p>
                                        <p align="justify"><span class="Testo">Sono una ragazza 25 enne..e  a mio fratello…un bellissimo ragazzo oggi 17enne (e quindi in quella fase più  difficile dell’adolescenza)…10 anni fa circa (un’estate) comparve una piccola  macchiolina sulla schiena…i primi controlli e le prime diagnosi fino a scoprire  la vitiligine. <br/>
            Dalla schiena ai gomiti e poi alle ginocchia, fino ad arrivare sul viso e poi  in ogni parte del corpo.. e un lungo iter di visite, di lampade speciali (circa  30 sedute), persino un viaggio in spagna da un medico che ci rispose “dovreste  venire a vivere qui” (noi siamo di milano) - per far prendere il sole al  ragazzino 10 mesi l’anno insieme ad unguenti e pillole varie…neanche il trucco  funziona data l’estensione delle macchie su tutto il corpo!<br/>
            &nbsp;<br/>
            E lui…un 17enne che d’estate non mette mai i pantaloni corti…al mare sta sempre  con la maglietta e tende a frequentare sempre gli stessi amici (anche se è un  ragazzo molto socievole e simpatico)…forse per paura di dover spiegare ogni  volta il perché di quelle macchie…e noi famigliari che un bel giorno d’estate  ci sentiamo dire…”tanto noi siete voi ad essere chiamati “cani dalmata”…un  colpaccio allo stomaco!!!<br/>
            &nbsp;<br/>
            &nbsp;<br/>
            Ad oggi mio fratello non si sta sottoponendo a cure (non tanto per la perdita  di speranza nella guarigione) quando perché ogni volta che cerchiamo di parlare  di questa cosa lui si chiude a riccio e a noi sembra di dare “inutili  speranze”.<br/>
            &nbsp;<br/>
            &nbsp;<br/>
            Siamo una famiglia unita e questo lo ha aiutato molto a capire che esistono  cose più importanti nella vita che un fantastico aspetto fisico ma per lui non  è comunque facile...per questo penso che presto ci iscriveremo  all’associazione…<br/>
            <br/>
            Grazie<br/>
            <em><strong>Ilaria<br/>
            </strong></em></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" class="fasciaTitoli">
                                        <div align="right"><span class="Titolo"><a href="#elenco">torna all'elenco ↑</a></span>&nbsp;</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><strong><a id="16" name="26"></a></strong></p>
                                        <p><br/>
                                            &nbsp;</p>
                                        <p><strong><span class="Titolo">• Ciao sono Andrea da Padova, 40 anni single. <br/>
            &nbsp;&nbsp;            Ho la vitiligine d 6 anni.</span><br/>
                                        </strong></p>
                                        <p align="justify">&nbsp;</p>
                                        <span class="Testo">
            <p align="justify">All'inizio è stata scambiata per un fungo sulle mani e visto che dopo 1 settimana di pomate non passavano le macchioline decisi di sottopormi ad una visita dermatologica.</p>
            <p align="justify">Il Dottore mi disse: Lei è' sfortunato, ha la vitiligine. Non mi diede speranze ma mi prescrisse delle terapie con lampade uvb alle alle mani e polsi.</p>
            <p align="justify">Dopo circa 30 sedute nessun risultato.             Intanto la progressione della patologia  che avanzava.Io che per natura sono molto ordinato e curato non accettavo e non accetto un simile flagello per cui mi sottoposi a mie spese presso un ambulatorio privato a cicli di fotorerapia.Nel frattempo comparivano macchie su area perioculare,bocca, genitali e piega interglutea.             Il risultato sul viso è stato discreto. No invece su altre zone.</p>
            <p align="justify">Cessate le sedute dopo qualche tempo le macchie ricomparivano sulle zone appena trattate. Che avvilimento !!               Ora non sto facendo nessuna terapia se non quella di espormi al sole con adeguati filtri solari e mangiare frutta e verdura di cui sono molto ghiotto.               A tutt'oggi sono 11 le zone del mio corpo interessate da vitiligine.                              Mi ha completamente cambiato la vita,mi sono chiuso,isolato e non ho grandi speranze di guargione.              L'unica cosa che mi aiuta e' il cammuflage.</p>
            <p align="justify">Che stress prepararmi per andare al lavoro!!.               Una cosa che mi ha colpito è stato il rivedere in occasione di un matrimonio amici di vecchia data e notare come loro siano rimasti esteticamente gli stessi. Io no, e dentro di me urlavo.               Spesso mi chiedo perché a me che non fumo non bevo non mi drogo e non prendo pastiglie di alcun genere, mi sia capitata una cosa simile!!               A volte provo un senso di invidia verso chi non ha questa malattia.</p>
            <p align="justify">Mi sento inferiore anche verso il piu' ultimo disgraziato presente su questa  terra.               L' arrivo della primavera per me e' un incubo perche' come ben sapete e' con i primi raggi di sole che si evidenziano le macchie.              In inverno vivo abbastanza bene.</p>
            <p align="justify">Quest'anno sono stato al congresso di Roma. Mi ha fatto bene andarci.<br/>
            Penso che mi iscrivero' all' ARIV.</p>
            <p align="justify">Sincero grazie</p>
            <p align="justify"><em><strong> Andrea            </strong></em></p>
            </span>
                                        <p align="justify">&nbsp;</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td bgcolor="#e6f5ff" class="fasciaTitoli">
                                        <div align="right"><a href="#elenco"><span class="Titolo">torna all'elenco ↑</span></a>&nbsp;</div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
        </div>
        </div>

            <Footer/>
        </div>
    )
}

export default LaTuaStoria;