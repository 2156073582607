import './ProfCard.css'

function ProfCard(props){
    return(
        <div className='prof_card'>
            <img src={props.img}/>
            <div className='prof_desc'>
                <name>{props.name}</name>
                <p className='p_desc'>{props.desc}</p>
            </div>
        </div>
    )
}

export default ProfCard