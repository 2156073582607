import React, { useState } from 'react';

const DescriptionToggle = ({ description, link }) => {
    const [showDescription, setShowDescription] = useState(false);

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    return (
        <div>
            <a onClick={toggleDescription}>
                {description}
            </a>
            {showDescription && <p>{link}</p>}
        </div>
    );
};

export default DescriptionToggle;
