import './ComitatoScientifico.css'
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import Footer from "../Footer/Footer";
import React, { useEffect } from 'react';
import ProfCard from "../ProfCard/ProfCard";

import prof1 from '../images/Luigi-Naldi-New.jpg'
import prof2 from '../images/giannetti.png'
import prof3 from '../images/Dorothy-Bennett-4.png'
import prof4 from '../images/Lionel-Larue.png'
import prof5 from '../images/завантаження.jfif'
import prof6 from '../images/FOTO_RETTORE_NOVELLI.webp'
import prof7 from '../images/Professeur-Jean-Paul-Ortonne-.png'
import prof8 from '../images/Olsson_Mats_small.jpg'
import prof9 from '../images/Mauro-Picardo.png'
import prof10 from '../images/rich-spritz.png'

function ComitatoScientifico() {
    return (
        <div className='comitato_scientifico'>
            <Header/>
            <div className='comitato_scientifico_content'>
                <Menu/>
                <div className='comitato_scientifico_filling'>
                    <div id="mainarea">
                        <div className="catgroupnamearea">
                            <h1 className="heading">Comitato scientifico » </h1>
                            <ProfCard img={prof1} name='Dr. Luigi Naldi'
                                      desc={
                                          <>
                                              Direttore del Centro Studi GISED <br />
                                              e dermatologo presso l'Unità complessa di Dermatologia degli Ospedali Riuniti di Bergamo.
                                          </>
                                      }/>
                            <ProfCard img={prof2} name="Prof. Alberto Giannetti"
                                      desc={
                                          <>
                                              I principali campi di studio del Prof. Alberto Giannetti sono: Biologia
                                              della Cute, Immunologia e Allergologia <br/> Dermatologica, Dermatologia
                                              Pediatrica, Oncologia Dermatologica, sui <br/>quali
                                              ha scritto dei capitoli sui principali testi italiani delle discipline
                                              generali.<br/>
                                              E’ membro attivo delle principali società di Dermatologia italiane ed
                                              internazionali.
                                          </>
                                      }/>
                            <ProfCard img={prof3} name="Dorothy C. Bennett, MA, PhD"
                                      desc={
                                          <>
                                              Professor of Cell Biology<br/>
                                              Centre for Molecular and Metabolic Signalling<br/>
                                              Division of Basic Medical Sciences<br/>
                                              St. George's, University of London
                                          </>
                                      }/>
                            <ProfCard img={prof4} name="Lionel Larue PhD"
                                      desc={
                                          <>
                                              Developmental Genetics of Melanocytes<br/>
                                              UMR146 CNRS, Institut Curie<br/>
                                              Bat 110, Centre universitaire<br/>
                                              91405 Orsay Cedex<br/>
                                              France
                                          </>
                                      }/>
                            <ProfCard img={prof5} name="Prof. Silvia Moretti"
                                      desc={
                                          <>
                                              Professore Associato in Dermatologia,<br/>
                                              Dipartimento di Scienze Dermatologiche,<br/>
                                              Università di Firenze
                                          </>
                                      }/>
                            <ProfCard img={prof6} name="Prof. Giuseppe Novelli"
                                      desc={
                                          <>
                                              "Direttore Laboratorio di Genetica Medica<br/>
                                              Azienda Ospedaliera Policlinico Tor Vergata<br/>
                                              Viale Oxford 1<br/>
                                              00133 Roma (Italy)"
                                          </>
                                      }/>
                            <ProfCard img={prof7} name="Prof. Jean-Paul Ortonne" desc={
                                <>
                                    Professore di Dermatologia<br/>
                                    all'Università di Nizza, (Francia)<br/>
                                    Chairman del Dipartimento di Dermatologia.
                                </>
                            }/>
                            <ProfCard img={prof8} name="Dr. Mats J. Olsson"
                                      desc={
                                          <>
                                              Department of Medical Sciences<br/>
                                              Section of Dermatology & Venereology<br/>
                                              University Hospital<br/>
                                              SE-751 85 Uppsala<br/>
                                              SVEZIA
                                          </>
                                      }/>
                            <ProfCard img={prof9} name="Dr. Mauro Picardo" desc={
                                <>
                                    Responsabile Laboratorio di Fisiopatologia Cutanea<br/>
                                    Istituto Dermatologico San Gallicano IRCCS<br/>
                                    Roma
                                </>
                            }/>
                            <ProfCard img={prof10} name="Richard A. Spritz, M.D." desc={
                                <>
                                    Professor of Pediatrics & Director<br/>
                                    of the Human Medical Genetics Program<br/>
                                    University of Colorado Health Sciences Center<br/>
                                    Denver, Colorado
                                </>
                            }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ComitatoScientifico;