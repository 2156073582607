import './Header.css'
import banner from '../images/banner.jpg'
function Header(){
    return(
        <div className='header'>
            <a href='' className='img_href'><img className='banner' src={banner}/></a>
        </div>
    )
}

export default Header;