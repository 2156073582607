import './DallaRicerca.css'
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Menu from "../Menu/Menu";

function DallaRicerca() {
    return (
        <div className='dalla_ricerca'>
            <Header/>
            <div className='dalla_ricerca_content'>
                <Menu/>
                <div className='dalla_ricerca_filling'>
                    <div id="mainarea">
                        <div className="catgroupnamearea">
                            <h1 className="heading">Dalla ricerca » </h1>
                        </div>

                        <div id="maincontarea">
                            <p><a id="inizio" name="inizio"></a></p>
                            <p><span className="Testo">C'è un incremento significativo di interesse da parte del mondo scientifico per la nostra malattia e per i pesanti risvolti psicologici e sociali che spesso pesano sul paziente. Questi studi che mensilmente vengono pubblicati,da ogni parte del mondo, ne sono la testimonianza.<br/>
Questa sezione vuole essere una risorsa per i dermatologi </span><span
                                className="Testo">e per i pazienti&nbsp; </span><span className="Testo">che desiderano esser tempestivamente aggiornati sulla ricerca e sui suoi sviluppi clinici.<br/>
<br/>
ARIV fornisce l’ elenco mensile degli studi pubblicati.Dal titolo di ogni studio si può facilmente risalire al testo integrale .<br/>
<br/>
</span></p>
                            <p><strong><a href="#1">- STUDI DI REVISONE</a><br/>
<br/>
<a href="#2">- STUDI CLINICI (vale a dire fatti sui pazienti)</a><br/>
<br/>
<a href="#3">- STUDI DI RICERCA DI BASE (vale a dire ricerca di laboratorio)</a></strong></p>
                            <p><span className="Testo"><br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <br/>
                                <a id="artMedici" name="1"></a><strong>STUDI DI REVISIONE<br/>
<br/>
</strong></p>
                            <p><span className="Testo"><b><b><b><b>MAGGIO     2011<br/>
<br/>
</b></b></b></b>Kawakami T, Hashimoto T. Disease severity indexes and treatment evaluation criteria in vitiligo. Dermatol Res Pract. 2011.  <br/>
<br/>
Yaghoobi R, Omidian M, Bagherani N. Vitiligo: a review of the published work. J Dermatol. 2011 May;38(5):419-31.  <br/>
<br/>
Karelson M, Silm H, Salum T, Kõks S, Kingo K. Differences between familial and sporadic cases of vitiligo. J Eur Acad Dermatol Venereol. 2011 May 31.  <br/>
<br/>
Batchelor JM, Whitton ME, Pinart M, Leonardi-Bee J, Gonzalez U. Comment on: Vitiligo Treatment in Childhood: a State of the Art Review. By Tamesis MEB, Morelli JG: Pediatric Dermatology 2010;27(5):437-455. Pediatr Dermatol. 2011 May-Jun;28(3):354-5.  <br/>
<br/>
Pichaimuthu R, Ramaswamy P, Bikash K, Joseph R. A measurement of the stigma among vitiligo and psoriasis patients in India. Indian J Dermatol Venereol Leprol. 2011 May-Jun;77(3):300-6. <br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------<br/>
</span></p>
                            <p><span className="Testo"><b><b><br/>
APRILE    2011<br/>
<br/>
</b></b><em>Nunes DH, Esser LM. Vitiligo epidemiological profile and the association with thyroid disease. An Bras Dermatol. 2011 Apr;86(2):241-8.<br/>
<b><b><br/>
<br/>
</b></b>-------------------------------------------------------------------------------------------------------------------------------------------------------------------<br/>
</em></span></p>
                            <p><em><span className="Testo"><b><b><br/>
MARZO   2011<br/>
<br/>
</b></b>Nordlund JJ. Vitiligo: a  review of some facts lesser known about depigmentation. Indian J Dermatol. 2011 Mar;56(2):180-9.<br/>
<br/>
Matin R. Vitiligo in adults and children. Clin Evid (Online). 2011 Mar 28;2011.<br/>
<br/>
Eleftheriadou V, Whitton ME, Gawkrodger DJ,  Batchelor J, Corne J, Lamb B, Ersser S, Ravenscroft J, Thomas  KS; on behalf of the vitiligo priority setting partnership. Future research  into the treatment of vitiligo:  where should our priorities lie? Results of the vitiligo priority  setting partnership. Br J Dermatol. 2011 Mar;164(3):530-536.<br/>
<b><b><br/>
</b></b><u><b><b><br/>
</b></b></u>-------------------------------------------------------------------------------------------------------------------------------------------------------------------<br/>
</span></em></p>
                            <p><u> </u></p>
                            <p><em><span className="Testo"><b><br/>
FEBBRAIO 2011</b></span></em><u><em><span className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo">Boissy RE, Nordlund JJ. Vitiligo: current medical and scientific understanding. G Ital Dermatol Venereol. 2011 Feb;146(1):69-75.<br/>
<br/>
Uncu S, Yaylı S, Bahadır S, Okten A, Alpay K. Relevance of autoimmune thyroiditis in children and adolescents with vitiligo. <br/>
Int J Dermatol. 2011 Feb;50(2):175-9.<br/>
<br/>
Westerhof W, Manini P, Napolitano A, d'Ischia M. The haptenation theory of vitiligo and melanoma rejection: a close-up. Exp Dermatol. 2011 Feb;20(2):92-6.<br/>
<br/>
van Geel N, Speeckaert R, Taieb A, Picardo M, Böhm M, Gawkrodger DJ, Schallreuter K, Bennett DC, van der Veen W, Whitton M, Moretti S, Westerhof W, Ezzedine K, Gauthier Y; on behalf of the other VETF members. Koebner's phenomenon in vitiligo: European position paper. Pigment Cell Melanoma Res. 2011 Feb 16.<br/>
<br/>
Sandoval-Cruz M, García-Carrasco M, Sánchez-Porras R, Mendoza-Pinto C, Jiménez-Hernández M, Munguía-Realpozo P, Ruiz-Argüelles A. Immunopathogenesis of Vitiligo. Autoimmun Rev. 2011 Feb 17.<br/>
<br/>
Glassman SJ. Vitiligo, reactive oxygen species and T-cells. Clin Sci (Lond). 2011 Feb;120(3):99-120<strong>.</strong></span></em><u><em><span
                                className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span
                                className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo"><b>DICEMBRE 2010</b></span></em><u><em><span
                                className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo">Kim DY, Oh SH, Hann SK. Classification of segmental vitiligo on the face: Clues for prognosis. Br J Dermatol. 2010 Dec 27.<br/>
<br/>
Bilgiç O, Bilgiç A, Akiş HK, Eskioğlu F, Kiliç EZ. Depression, anxiety and health-related quality of life in children and adolescents with vitiligo. Clin Exp Dermatol. 2010 Dec 24.<br/>
<br/>
Arunachalam M, Sanzo M, Lotti T, Colucci R, Berti S, Moretti S. Common variable immunodeficiency in vitiligo. G Ital Dermatol Venereol. 2010 Dec;145(6):783-8.<br/>
<br/>
Batalla A, Feal JC. Linear Vitiligo Going on a Varix Way: Possible Association with Koebner Phenomenon. Actas Dermosifiliogr. 2010 Dec;101(10):902-903.<br/>
<br/>
Arunachalam M, Sanzo M, Lotti T, Colucci R, Berti S, Moretti S. Common variable immunodeficiency in vitiligo. G Ital Dermatol Venereol. 2010 Dec;145(6):783-8.<br/>
<br/>
Eleftheriadou V, Whitton ME, Gawkrodger DJ, Batchelor J, Corne J, Lamb B, Ersser S, Ravenscroft J, Thomas KS; on behalf of the vitiligo priority setting partnership. Future research into the treatment of vitiligo: where should our priorities lie? Results of the vitiligo priority setting partnership. Br J Dermatol. 2010 Dec 3.<br/>
<br/>
Paterson E. Vitiligo. J Vis Commun Med. 2010 Dec;33(4):166-8.<strong><br/>
<br/>
</strong>-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo"><b>NOVEMBRE 2010</b><br/>
<br/>
Hwang SW, Kang JH, Jung SY, Choi JH, Seo JK, Lee D, Sung HS. Vitiligo Coexistent with Nevus Depigmentosus: This Was Treated with Narrow-Band UVB and These Lesions Were Followed Using the Mexameter®, a Pigment-Measuring Device. Ann Dermatol. <br/>
2010 Nov;22(4):482-5.<br/>
<br/>
Kanwar A, Parsad D, De D. Mucosal involvement in vitiligo: a comprehensive review of 241 cases. J Eur Acad Dermatol Venereol. <br/>
2010 Nov 25.<br/>
<br/>
Bhalla M, Thami GP. Factors predicting the preference of complementary and alternative systems of medicine in treatment of vitiligo. Indian J Dermatol Venereol Leprol. 2010 Nov-Dec;76(6):705-7.<br/>
<br/>
Alghamdi K, Kumar A. Depigmentation therapies for normal skin in vitiligo universalis. J Eur Acad Dermatol Venereol. <br/>
2010 Nov 4.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <b><br/>
OTTOBRE 2010</b><br/>
<br/>
Spritz RA. The genetics of generalized vitiligo: autoimmune pathways and an inverse relationship with malignant melanoma. Genome Med. 2010 Oct 19;2(10):78.<br/>
<br/>
AlGhamdi KM. Beliefs and perceptions of Arab vitiligo patients regarding their condition. Int J Dermatol. 2010 Oct;49(10):1141-5.<br/>
<br/>
Majid I. Does topical tacrolimus ointment enhance the efficacy of narrowband ultraviolet B therapy in vitiligo? A left-right comparison study. Photodermatol Photoimmunol Photomed. 2010 Oct;26(5):230-4.<br/>
<br/>
Mashayekhi V, Javidi Z, Kiafar B, Manteghi AA, Saadatian V, Esmaeili HA, Hosseinalizadeh S. Quality of life in patients with vitiligo: a descriptive study on 83 patients attending a PUVA therapy unit in Imam Reza Hospital, Mashad. Indian J Dermatol Venereol Leprol. 2010 Sep-Oct;76(5):592.<br/>
<br/>
Pahwa M, Gupta S, Khunger N. Multimodal single-step vitiligo surgery: a novel approach. Dermatol Surg. 2010 Oct;36(10):1627-31.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>AGOSTO 2010</b><br/>
<br/>
Viles J, Monte D, Gawkrodger DJ. Vitiligo. BMJ. 2010 Aug 20;341.<br/>
<br/>
Gawkrodger DJ, Ormerod AD, Shaw L, Mauri-Sole I, Whitton ME, Watts MJ, Anstey AV, Ingham J, Young K. Vitiligo: concise evidence based guidelines on diagnosis and management. Postgrad Med J. 2010 Aug;86(1018):466-71.<br/>
<br/>
Silverberg NB. Update on childhood vitiligo. Curr Opin Pediatr. 2010 Aug;22(4):445-52.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> </span></em><u><em><span className="Testo"><br/>
</span></em></u><em><span className="Testo"><b>LUGLIO 2010</b><br/>
<br/>
Wang KY, Wang KH, Zhang ZP. Health-related quality of life and marital quality of vitiligo patients in China. J Eur Acad Dermatol Venereol. 2010 Jul 28.<br/>
<br/>
Spritz RA. Shared genetic relationships underlying generalized vitiligo and autoimmune thyroid disease. Thyroid. 2010 Jul;20(7):745-54.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>GIUGNO 2010</b><br/>
<br/>
Tamesis ME, Morelli JG. Vitiligo Treatment in Childhood: A State of the Art Review. Pediatr Dermatol. 2010 Jun 9.<br/>
<br/>
Guerra L, Dellambra E, Brescia S, Raskovic D. Vitiligo: pathogenetic hypotheses and targets for current therapies. Curr Drug Metab. 2010 Jun 1;11(5):451-67.<br/>
<br/>
Mazereeuw-Hautier J, Bezio S, Mahe E, Bodemer C, Eschard C, Viseux V, Labreze C, Plantin P, Barbarot S, Vabres P, Martin L, Paul C, Lacour JP; Groupe de Recherche Clinique en Dermatologie Pédiatrique (GRCDP). Segmental and nonsegmental childhood vitiligo has distinct clinical characteristics: a prospective observational study. J Am Acad Dermatol. 2010 Jun;62(6):945-9.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>MAGGIO 2010</b><br/>
<br/>
Bowen C, Kobayashi TT. What is your diagnosis? Segmental vitiligo and en coup de sabre. Cutis. 2010 May;85(5):230, 237-8.<br/>
<br/>
Ingordo V, Gentile C, Iannazzone S, Cusano F, Naldi L. Vitiligo and autoimmunity: an epidemiological study in a representative sample of young Italian males. J Eur Acad Dermatol Venereol. 2010 May 7.<br/>
<br/>
Abu Tahir M, Pramod K, Ansari SH, Ali J. Current remedies for vitiligo. Autoimmun Rev. 2010 May;9(7):516-20.<br/>
<br/>
--------------------------------------------------------------------------------------------------------------------------<br/>
<br/>
<b>APRILE 2010</b><br/>
<br/>
Perossini M, Turio E, Perossini T, Cei G, Barachini P. Vitiligo: ocular and electrophysiological findings. G Ital Dermatol Venereol. 2010 Apr;145(2):141-9.<br/>
<br/>
Cho SB, Kim JH, Cho S, Park JM, Park YK, Oh SH. Vitiligo in children and adolescents: association with thyroid dysfunction. J Eur Acad Dermatol Venereol. 2010 Apr 28.<br/>
<br/>
Thompson AR, Clarke SA, Newell RJ, Gawkrodger DJ; The Appearance Research Collaboration (ARC). Vitiligo linked to stigmatization in British South Asian women: A qualitative study of the experiences of living with vitiligo. Br J Dermatol. 2010 Apr 26.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>MARZO 2010</b><br/>
Agretti, P, G De Marco, D Sansone, C Betterle, G Coco, A Dimida, E Ferrarini, A Pinchera, P Vitti, and M Tonacchera. 2010. PATIENTS AFFECTED BY VITILIGO AND AUTOIMMUNE DISEASES DO NOT SHOW ANTIBODIES INTERFERING WITH THE ACTIVITY OF THE MELANOCORTIN 1 RECEPTOR. Journal of Endocrinological Investigation (March 22).<br/>
<br/>
<br/>
Aroni, K, S Voudouris, E Ioannidis, A Grapsa, N Kavantzas, and E Patsouris. 2010. Increased angiogenesis and mast cells in the centre compared to the periphery of vitiligo lesions. Archives of Dermatological Research (March 5).<br/>
<br/>
Birlea, Stanca A, Katherine Gowan, Pamela R Fain, and Richard A Spritz. 2010. Genome-wide association study of generalized vitiligo in an isolated European founder population identifies SMOC2, in close proximity to IDDM8. The Journal of Investigative Dermatology 130, no. 3 (March): 798-803.<br/>
<br/>
<br/>
Coelho, Joana Dias, and Ana Ferreira. 2010. Letter: Association of targeted intense pulse light system UVA1-UVB and fluticasone in the treatment of vitiligo: Prospective study of 10 patients. Dermatology Online Journal 16, no. 3: 15.<br/>
<br/>
Jin, Ying, Sheri L Riccardi, Katherine Gowan, Pamela R Fain, and Richard A Spritz. 2010. Fine-mapping of vitiligo susceptibility loci on chromosomes 7 and 9 and interactions with NLRP1 (NALP1). The Journal of Investigative Dermatology 130, no. 3 (March): 774-783.<br/>
<br/>
Kumar, Pramod, and Atula Gupta. 2010. Dermatoglyphic patterns in psoriasis, vitiligo and alopecia areata. Indian Journal of Dermatology, Venereology and Leprology 76, no. 2 (April): 185-186.<br/>
<br/>
Le Duff, F, E Fontas, D Giacchero, L Sillard, J-P Lacour, J-P Ortonne, and T Passeron. 2010. 308 nm excimer lamp versus 308 nm excimer laser for treating vitiligo: a randomized study. The British Journal of Dermatology (March 23).<br/>
Patel, Nirav, Judith O’Haver, and Ronald C Hansen. 2010. Vitiligo Therapy in Children: A Case for Considering Excimer Laser Treatment. Clinical Pediatrics (March 22).<br/>
<br/>
Radtke, Marc A, Ines Schäfer, Angelika I Gajur, and Matthias Augustin. 2010. Clinical Features and Treatment Outcomes of Vitiligo from the Patients’ Perspective: Results of a National Survey in Germany. Dermatology (Basel, Switzerland) (March 5).<br/>
<br/>
<br/>
Smith, Aaron G, and Richard A Sturm. 2010. Multiple genes and locus interactions in susceptibility to vitiligo. The Journal of Investigative Dermatology 130, no. 3 (March): 643-645.<br/>
<br/>
Tanioka, Miki, Yosuke Yamamoto, Mayumi Kato, and Yoshiki Miyachi. 2010. Camouflage for patients with vitiligo vulgaris improved their quality of life. Journal of Cosmetic Dermatology 9, no. 1 (March): 72-75.<br/>
<br/>
Wind, B S, M W Kroon, J F Beek, J P W van der Veen, L Nieuweboer-Krobotová, A A Meesters, J D Bos, and A Wolkerstorfer. 2010. Home vs. outpatient narrowband ultraviolet B therapy for the treatment of nonsegmental vitiligo:<br/>
a retrospective questionnaire study. The British Journal of Dermatology (March 1).<br/>
<br/>
Xu, Shengxin, Youwen Zhou, Sen Yang, Yunqing Ren, Chi Zhang, Cheng Quan, Min Gao, et al. 2010. Platelet-derived growth factor receptor alpha gene mutations in vitiligo vulgaris. Acta Dermato-Venereologica 90, no. 2 (March): 131-135.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> </span></em><u><em><span className="Testo"><br/>
</span></em></u><em><span className="Testo"><b>FEBBRAIO 2010</b><br/>
Abu Tahir, M, K Pramod, S H Ansari, and Javed Ali. 2010. Current remedies for vitiligo. Autoimmunity Reviews (February 8).<br/>
<br/>
<br/>
Akay, B N, M Bozkir, Y Anadolu, and S Gullu. 2010. Epidemiology of vitiligo, associated autoimmune diseases and audiological abnormalities: Ankara study of 80 patients in Turkey. Journal of the European Academy of Dermatology and Venereology: JEADV (February 25).<br/>
<br/>
Al-Mutairi, Nawaf, Yashpal Manchanda, Azari Al-Doukhi, and Ahmad Al-Haddad. 2010. Long-Term Results of Split-Skin Grafting in Combination with Excimer Laser for Stable Vitiligo. Dermatologic Surgery: Official Publication for American Society for Dermatologic Surgery [et Al (February 17).<br/>
<br/>
de Castro, Caio Cesar Silva, Liliane Machado do Nascimento, Gaby Walker, Renata Iani Werneck, Everson Nogoceke, and Marcelo Távora Mira. 2010. Genetic Variants of the DDR1 Gene Are Associated with Vitiligo in Two Independent Brazilian Population Samples. The Journal of Investigative Dermatology (February 25).<br/>
<br/>
Christou, Elizabeth M, Diona L Damian, and John F Thompson. 2010. Regressing metastatic melanoma and vitiligo-like depigmentation in an Indigenous Australian. The Medical Journal of Australia 192, no. 3 (February 1): 171.<br/>
<br/>
Holla, A P, and D Parsad. 2010. Vitiligo surgery: its evolution as a definite treatment in the stable vitiligo. Giornale Italiano Di Dermatologia E Venereologia: Organo Ufficiale, Società Italiana Di Dermatologia E Sifilografia 145, no. 1 (February): 79-88.  <br/>
<br/>
Hossani-Madani, A R, and R M Halder. 2010. Topical treatment and combination approaches for vitiligo: new insights, new developments. Giornale Italiano Di Dermatologia E Venereologia: Organo Ufficiale, Società Italiana Di Dermatologia E Sifilografia 145, no. 1 (February): 57-78.<br/>
<br/>
Jeong, Taek-Jo, Min-Kyung Shin, Yoon-Kyung Uhm, Hak-Jae Kim, Joo-Ho Chung, and Mu-Hyoung Lee. 2010. Association of UVRAG polymorphisms with susceptibility to non-segmental vitiligo in a Korean sample. Experimental Dermatology (February 16).<br/>
<br/>
Liu, J, W Y Man, C Z Lv, S P Song, Y J Shi, P M Elias, and M Q Man. 2010. Epidermal Permeability Barrier Recovery Is Delayed in Vitiligo-Involved Sites. Skin Pharmacology and Physiology 23, no. 4 (February 25): 193-200.<br/>
<br/>
Lo, Yuan-Hsin, Gwo-Shing Cheng, Chieh-Chen Huang, Wen-Yu Chang, and Chieh-Shan Wu. 2010. Efficacy and safety of topical tacrolimus for the treatment of face and neck vitiligo. The Journal of Dermatology 37, no. 2 (February): 125-129.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>GENNAIO 2010</b><br/>
Ali, Rubaiya, Mohammad Shamsul Ahsan, Mohammad Abul Kalam Azad, Md Ashik Ullah, Wasimul Bari, Sheikh Nazrul Islam, Sabina Yeasmin, and Abul Hasnat. 2010. Immunoglobulin levels of vitiligo patients. Pakistan Journal of Pharmaceutical Sciences 23, no. 1 (January): 97-102.<br/>
<br/>
Kostyuk, Vladimir, Alla Potapovich, Eleonora Cesareo, Serena Brescia, Liliana Guerra, Giuseppe Valacchi, Alessandra Pecorelli, et al. 2010. Dysfunction of Glutathione S-Transferase Leads to Excess 4-Hydroxy-2-Nonenal and H2O2 and Impaired Cytokine Pattern in Cultured Keratinocytes and Blood of Vitiligo Patients. Antioxidants &amp; Redox Signaling (January 13).<br/>
<br/>
Whitton, Maxine E, Mariona Pinart, Jonathan Batchelor, Clare Lushey, Jo Leonardi-Bee, and Urbà González. 2010. Interventions for vitiligo. Cochrane Database of Systematic Reviews (Online), no. 1: CD003263.  <br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>DICEMBRE 2009</b><br/>
<br/>
Nordlund J. Cancro della pelle non- melanoma in pazienti con vitiligine. J Am Acad Dermatol. 2009 Dec; 61 (6) :1080-1.<br/>
<br/>
Lotti T, Berti S, Moretti S. terapia della vitiligine. Expert Opin Pharmacother. 2009 Dec; 10 (17) :2779-85.<br/>
<br/>
Jalel A, Soumaya GS, Hamdaoui MH. Punteggi della qualità della vita Dermatologia indice in vitiligine: affidabilità e la validità della versione tunisina. Indian J Dermatol. 2009; 54 (4) :330-3.<br/>
<br/>
Falabella R. Vitiligo  e  serbatoio  di melanociti. Indian J Dermatol. 2009; 54 (4) :313-8.<br/>
<br/>
K. Lahiri Evoluzione e  valutazione di mini trapianto autologo  in vitiligine. Indian J Dermatol. 2009; 54 (2) :159-67.<br/>
<br/>
Khunger N, Kathuria SD, Ramesh : Chirurgia della Vitiligine - passato, presente e futuro. <br/>
Indian J Dermatol. 2009; 54 (2) :150-8.<br/>
<br/>
Talsania N, Lamb B, Bewley A. La vitiligine  a fior di pelle.   Clin Exp Dermatol. 2009 Dec 8.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>NOVEMBRE 2009</b><br/>
<br/>
Kakourou T. Vitiligine nei bambini. World J Pediatr. 2009 Nov; 5 (4) :265-8.<br/>
<br/>
Osman AM, Elkordufani Y, Abdullah MA. L'impatto psicologico della vitiligine nei pazienti adulti sudanesi. Afr J Psychiatry (Johannesbg). 2009 Nov; 12 (4) :284-6.<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>OTTOBRE 2009</b><br/>
<br/>
Gawkrodger DJ. Vitiligine: Quello che i medici di base hanno bisogno di sapere. Clin Med. 2009 Oct; 9 (5) :408-9.<br/>
<br/>
Gawkrodger DJ. Pseudocatalase  e UVB a banda stretta per la vitiligine:  Br J Dermatol. 2009 Oct; 161 (4) :721-2.<br/>
<br/>
TS Anbar, Abdel-Raouf H, Awad SS, Ragaie NH, Abdel-Rahman AT. Presenza di eosinofili nell' infiltrato infiammatorio della vitiligine non segmentale: studio retrospettivo. Clin Exp Dermatol. 2009 Oct; 34 (7): e496-7.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>SETTEMBRE 2009<br/>
</b><br/>
Alzolibani A. Genetic epidemiology and heritability of vitiligo in the Qassim region of Saudi Arabia. Acta Dermatovenerol Alp Panonica Adriat. 2009 Sep;18(3):119-25.<br/>
<br/>
Michelsen D. The Double Strike Hypothesis of the vitiligo pathomechanism: New approaches to vitiligo and melanoma. <br/>
Med Hypotheses. 2009 Sep 10.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                <span className="Testo"> <br/>
</span></em></u><em><span className="Testo"><b>LUGLIO 2009</b><br/>
<br/>
Linthorst Homan MW, Spuls PI, de Korte J, Bos JD, Sprangers MA, Wietze van der Veen JP. The burden of vitiligo: Patient characteristics associated with quality of life. J Am Acad Dermatol. 2009 Jul 2.<br/>
<br/>
Boissy RE, Spritz RA. Frontiers and controversies in the pathobiology of vitiligo: separating the wheat from the chaff. Exp Dermatol. 2009 Jul; 18(7): 583-5.<br/>
<br/>
Kostopoulou P, Jouary T, Quintard B, Ezzedine K, Marques S, Boutchnei S, Taieb A. Objective vs. subjective factors in the psychological impact of vitiligo: the experience from a French referral centre. Br J Dermatol. 2009 Jul; 161(1): 128-33.<br/>
<br/>
Radtke MA, Schäfer I, Gajur A, Langenbruch A, Augustin M. Willingness-to-pay and quality of life in patients with vitiligo. Br J Dermatol. 2009 Jul; 161(1): 134-9.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>GIUGNO 2009</b><br/>
<br/>
Halder RM, Chappell JL. Vitiligo update. Semin Cutan Med Surg. 2009 Jun; 28(2): 86-92.<br/>
<br/>
Isenstein AL, Morrell DS, Burkhart CN. Vitiligo: treatment approach in children. Pediatr Ann. 2009 Jun; 38(6): 339-44.<br/>
<br/>
Alghamdi KM. A survey of vitiligo management among dermatologists in Saudi Arabia. J Eur Acad Dermatol Venereol. <br/>
2009 Jun 1.<br/>
<br/>
Kim do Y, Lee JW, Whang SH, Park YK, Hann SK, Shin YJ. Quality of life for Korean patients with vitiligo: Skindex-29 and its correlation with clinical profiles. J Dermatol. 2009 Jun; 36(6): 317-22.</span></em><u><em><span
                                className="Testo"><br/>
<br/>
</span></em></u><em><span
                                className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>MAGGIO 2009</b><br/>
<br/>
Singh AK, Karki D. Micropigmentation: Tattooing for the treatment of lip vitiligo. J Plast Reconstr Aesthet Surg. 2009 May 27.<br/>
<br/>
Parsad D, Kanwar AJ. Topical vitamin D(3) analogues in the treatment of vitiligo. Pigment Cell Melanoma Res. 2009 May 5.<br/>
<br/>
Birlea SA, Costin GE, Norris DA. New insights on therapy with vitamin D analogs targeting the intracellular pathways that control repigmentation in human vitiligo. Med Res Rev. 2009 May; 29(3): 514-46.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                <span className="Testo"> <br/>
<b>MARZO 2009</b><br/>
<br/>
Fernandes NC, Campos MM. Childhood vitiligo and thyroid disease. An Bras Dermatol. 2009 Mar-Apr; 84(2): 200-2.<br/>
<br/>
Namazi MR, Chee Leok GO. Vitiligo and diet: a theoretical molecular approach with practical implications. Indian J Dermatol Venereol Leprol. 2009 Mar-Apr; 75(2): 116-8.<br/>
<br/>
Kyriakis KP, Palamaras I, Tsele E, Michailides C, Terzoudi S. Case detection rates of vitiligo by gender and age. Int J Dermatol. 2009 Mar; 48(3): 328-9.<br/>
<br/>
Nicolaidou E, Antoniou C, Stratigos A, Katsambas AD. Narrowband ultraviolet B phototherapy and 308-nm excimer laser in the treatment of vitiligo: a review. J Am Acad Dermatol. 2009 Mar; 60(3): 470-7.</span></em><u><em><span
                                className="Testo"><br/>
<br/>
</span></em></u><em><span
                                className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                <span className="Testo"> <br/>
</span></em></u><em><span className="Testo"><b>FEBBRAIO 2009</b></span></em><u><em><span
                                className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">Cunha D, Pacheco FA, Cardoso J. Vitiligo: a good prognostic factor in melanoma? Dermatol Online J. 2009 Feb 15; 15(2): 15.<br/>
<br/>
Falabella R, Barona MI. Update on skin repigmentation therapies in vitiligo. Pigment Cell Melanoma Res. 2009 Feb; 22(1): 42-65.<br/>
<br/>
Zhang Z, Xu SX, Zhang FY, Yin XY, Yang S, Xiao FL, Du WH, Wang JF, Lv YM, Tang HY, Zhang XJ. The analysis of genetics and associated autoimmune diseases in Chinese vitiligo patients. Arch Dermatol Res. 2009 Feb; 301(2): 167-73.</span></em><u><em><span
                                className="Testo"><br/>
<br/>
</span></em></u><em><span
                                className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                <span className="Testo"> <br/>
</span></em></u><em><span className="Testo"><b>GENNAIO 2009</b><br/>
<br/>
Tanioka M, Miyachi Y. Camouflage for vitiligo. Dermatol Ther. 2009 Jan-Feb; 22(1): 90-3.<br/>
<br/>
Fadzil MH, Norashikin S, Suraiya HH, Nugroho H. Independent component analysis for assessing therapeutic response in vitiligo skin disorder. J Med Eng Technol. 2009; 33(2): 101-9.<br/>
<br/>
Vanscheidt W, Hunziker T. Repigmentation by outer-root-sheath-derived melanocytes: proof of concept in vitiligo and leucoderma. Dermatology. 2009; 218(4): 342-3.<br/>
<br/>
Taïeb A, Picardo M. Clinical practice. Vitiligo. N Engl J Med. 2009 Jan 8; 360(2): 160-9.<br/>
</span></em><u><em><span className="Testo"><br/>
</span></em></u></p>
                            <p><u>
                            </u></p>
                            <table width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td bgcolor="#dcf1f5">
                                        <div align="right"><em><a href="#inizio"><span className="Titolo">inizio pagina ↑</span></a></em>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <u>
                            </u><p></p>
                            <p><u><em><br/>
                                <br/>
                            </em></u><em><a name="2" id="studiClinici"></a><strong>STUDI CLINICI<br/>
<br/>
</strong></em></p>
                            <p><u> </u></p>
                            <p><b>GIUGNO 2011</b><br/>
                                <br/>
                                <span className="Testo">Mouzakis JA, Liu S, Cohen G. Rapid response of facial vitiligo to 308nm excimer laser and topical calcipotriene. J Clin Aesthet Dermatol. 2011 Jun;4(6):41-4.<br/>
<br/>
Linthorst Homan MW, Spuls PI, Nieuweboer-Krobotova L, de Korte J, Sprangers MA, Bos JD, Wolkerstorfer A, van der Veen JP. A randomized comparison of excimer laser versus narrow-band ultraviolet B phototherapy after punch grafting in stable vitiligo patients. J Eur Acad Dermatol Venereol. 2011 Jun 29.<br/>
<br/>
van Geel N, Speeckaert R, Lambert J, Mollet I, De Keyser S, De Schepper S, Brochez L. Halo naevi with associated vitiligo-like depigmentations: pathogenetic hypothesis. J Eur Acad Dermatol Venereol. 2011 Jun 23.<br/>
<br/>
Ismail WA, Al-Enzy SA, Alsurayei SA, Ismail AE. Vitiligo in a patient receiving infliximab for refractory ulcerative colitis. Arab J Gastroenterol. 2011 Jun;12(2):109-11.<br/>
<br/>
Pan ZY, Yan F, Zhang ZH, Zhang QA, Xiang LH, Zheng ZZ. In vivo reflectance confocal microscopy for the differential diagnosis between vitiligo and nevus depigmentosus. Int J Dermatol. 2011 Jun;50(6):740-5.<br/>
<br/>
Do JE, Shin JY, Kim DY, Hann SK, Oh SH. The effect of 308nm excimer laser on segmental vitiligo: a retrospective study of 80 patients with segmental vitiligo. Photodermatol Photoimmunol Photomed. 2011 Jun;27(3):147-51.</span><br/>
                                <br/>
                                <em><span
                                    className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                    <br/>
                                    <br/>
                                </em></u><span className="Testo"><b>MAGGIO 2011</b><br/>
<br/>
Silverberg JI, Silverberg NB. Topical tacrolimus is more effective for treatment of vitiligo in patients of skin of color. J Drugs Dermatol. 2011 May 1;10(5):507-10.<br/>
<br/>
Reddy IS, Swarnalatha G, Swain M. Asymptomatic papules and plaque in a patient with generalized vitiligo. Indian J Dermatol. 2011 May;56(3):341-2.<br/>
<br/>
Zhang R, Zhu W. Genital vitiligo following use of imiquimod 5% cream. Indian J Dermatol. 2011 May;56(3):335-6.<br/>
<br/>
Ezzedine K, Gauthier Y, Léauté-Labrèze C, Marquez S, Bouchtnei S, Jouary T, Taieb A. Segmental vitiligo associated with generalized vitiligo (mixed vitiligo): A retrospective case series of 19 patients. J Am Acad Dermatol. 2011 May 24.<br/>
<br/>
Karadag AS, Tutal E, Ertugrul DT. Insulin Resistance is Increased in Patients with Vitiligo. Acta Derm Venereol. 2011 May 20.<br/>
<br/>
Hong WS, Hu DN, Qian GP, McCormick SA, Xu AE. Ratio of size of recipient and donor areas in treatment of vitiligo by autologous cultured melanocytes transplantation. Br J Dermatol. 2011 May 12.<br/>
<br/>
Yao L, Li SS, Zhong SX, Song Y, Hu DN, Guo JW. Successful treatment of vitiligo on the axilla in a 5-year-old child by cultured-melanocyte transplantation. J Eur Acad Dermatol Venereol. 2011 May 5.<br/>
<br/>
Pan H, Song W, Xu A. A case of vitiligo induced by tattooing eyebrow. Int J Dermatol. 2011 May;50(5):607-8.<br/>
<br/>
Yamamoto Y, Tanioka M, Hayashino Y, Mishina H, Kato M, Fukuhara S, Utani A, Miyachi Y. Application of a two-question screening instrument to detect depressive symptoms in patients with vitiligo: a pilot study. J Am Acad Dermatol. 2011 May;64(5):e69-70.<br/>
<br/>
</span><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                </em></u><span className="Testo"><br/>
<br/>
<b>APRILE 2011</b><br/>
<br/>
Sharma P, Pai HS, Pai GS, Kuruvila M, Kolar R. Response to narrow-band UVB--vitiligo-melasma versus vitiligo: a comparative study. Am J Clin Dermatol. 2011 Apr 1;12(2):127-32.<br/>
<br/>
Lee DY, Kim CR, Lee JH. Targeted phototherapy in combination with drug therapy for segmental vitiligo. Photodermatol Photoimmunol Photomed. 2011 Apr;27(2):108-10.<br/>
<br/>
Lee DY, Kim CR, Lee JH. Trichrome vitiligo in segmental type. Photodermatol Photoimmunol Photomed. 2011 Apr;27(2):111-2.<br/>
<br/>
Nordal E, Guleng G, Rönnevig J. Treatment of vitiligo with narrowband-UVB (TL01) combined with tacrolimus ointment (0.1%) vs. placebo ointment, a randomized right/left double-blind comparative study. J Eur Acad Dermatol Venereol. 2011 Apr 6.<br/>
<br/>
Ho N, Pope E, Weinstein M, Greenberg S, Webster C, Krafchik BR. A double-blind, randomized, placebo-controlled trial of topical tacrolimus 0•1% vs. clobetasol propionate 0•05% in childhood vitiligo. Br J Dermatol. 2011 Apr 1.<br/>
<br/>
</span><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                </em></u><span className="Testo"><br/>
<br/>
<b>MARZO 2011</b><br/>
<br/>
Krüger C, Smythe JW, Spencer JD, Hasse S, Panske A, Chiuchiarelli G, Schallreuter KU. Significant Immediate and Long-term Improvement in Quality of Life and Disease Coping in Patients with Vitiligo after Group Climatotherapy at the Dead Sea. Acta Derm VenereoL. 2011 Mar;91(2):152-9.<br/>
<br/>
Narahari SR, Ryan TJ, Bose KS, Prasanna KS, Aggithaya GM. Integrating modern dermatology and Ayurveda in the treatment of vitiligo and lymphedema in India. Int J Dermatol. 2011 Mar;50(3):310-34.<br/>
<br/>
Schallreuter KU, Panske A, Chiuchiarelli G. Ineffective topical treatment of vitiligo with Cucumis melo extracts. Int J Dermatol. 2011 Mar;50(3):374-5.<br/>
<br/>
Lahita RG, Vernace MA. Vasculitis, vitiligo, thyroiditis, and altered hormone levels after anti-tumor necrosis factor therapy. J Rheumatol. 2011 Mar;38(3):579-80.<br/>
<br/>
Szczurko O, Shear N, Taddio A, Boon H. Ginkgo biloba for the treatment of vitilgo vulgaris: an open label pilot clinical trial. BMC Complement Altern Med. 2011 Mar 15;11:21.<br/>
<br/>
Ezzedine K, Diallo A, Léauté-Labrèze C, Mossalayi D, Gauthier Y, Bouchtnei S, Cario-André M, Seneschal J, Boralevi F, Jouary T, Taieb A. Multivariate analysis of factors associated with early onset segmental and non-segmental Vitiligo: a prospective observational study of 213 patients. Br J Dermatol. 2011 Mar 17.<br/>
<br/>
Kuchabal SD, Kuchabal DS. Alopecia Areata Associated with Localized Vitiligo. Case Rep Dermatol. 2010 Mar 27;2(1):27-31.<br/>
<br/>
Rodríguez-García C, González-Hernández S, Pérez-Robayna N, Guimerá F, Fagundo E, Sánchez R. Repigmentation of vitiligo lesions in a child with celiac disease after a gluten-free diet. Pediatr Dermatol. 2011 Mar-Apr;28(2):209-10.<br/>
<br/>
Jin Y, Xu A, Wang P, Song X, Liu X. Long-term follow-up and correlated factors of vitiligo following autologous epidermal transplantation. Cutis. 2011 Mar;87(3):137-41.<br/>
<br/>
Yaghoobi R, Omidian M, Bagherani N. Original article title: "Comparison of therapeutic efficacy of topical corticosteroid and oral zinc sulfate-topical corticosteroid combination in the treatment of vitiligo patients: a clinical trial". BMC Dermatol. 2011 Mar 31;11:7.<br/>
</span><u><em><span className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span
                                    className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                </em></u><u><em><span className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo"><b>FEBBRAIO 2011</b></span></em><u><em><span
                                    className="Testo"><strong><br/>
</strong></span></em></u><em><span className="Testo"><strong><br/>
</strong>Barikbin B, Kavand S, Yousefi M, Hedayati M, Saeedi M. No differences in serum selenium levels and blood glutathione peroxidase activities in patients with vitiligo compared with healthy control subjects. J Am Acad Dermatol. 2011 Feb;64(2):444-5.<br/>
<br/>
Silverberg JI, Silverberg NB. Serum homocysteine as a biomarker of vitiligo vulgaris severity: a pilot study. J Am Acad Dermatol. <br/>
2011 Feb;64(2):445-7.<br/>
<br/>
Anbar Tel-D, Abdel-Raouf H, Awad SS, Ragaie MH. Perifollicular inflammatory infiltrate in vitiligo. Int J Dermatol. 2011 Feb;50(2):234-7.<br/>
<br/>
Sahni K, Parsad D, Kanwar AJ, Mehta SD. Autologous noncultured melanocyte transplantation for stable vitiligo: can suspending autologous melanocytes in the patients' own serum improve repigmentation and patient satisfaction? Dermatol Surg. <br/>
2011 Feb;37(2):176-82.<br/>
<br/>
Quezada N, Machado Filho CA, De La Sotta P, Uribe P. Melanocytes and keratinocytes transfer using sandpaper technique combined with dermabrasion for stable vitiligo. DerMatol Surg. 2011 Feb;37(2):192-8.<br/>
<br/>
Tamler C, Duque-Estrada B, Oliveira PA, Avelleira JC. Tacrolimus 0,1% ointment in the treatment of vitiligo: a series of cases. An Bras Dermatol. 2011 Feb;86(1):169-172.<br/>
<br/>
Berti S, Bellandi S, Bertelli A, Colucci R, Lotti T, Moretti S. Vitiligo in an Italian outpatient center: a clinical and serologic study of 204 patients in Tuscany. Am J Clin Dermatol. 2011 Feb 1;12(1):43-9.<br/>
<br/>
Nigam PK, Patra PK, Khodiar PK, Gual J. A study of blood CD3+, CD4+, and CD8+ T cell levels and CD4+:CD8+ ratio in vitiligo patients. Indian J Dermatol Venereol Leprol. 2011 Jan-Feb;77(1):111.<br/>
<br/>
Antelo DP, Filgueira AL, Cunha JM. Reduction of skin-homing cytotoxic T cells (CD8+ -CLA+) in patients with vitiligo. <br/>
Photodermatol Photoimmunol Photomed. 2011 Feb;27(1):40-4.<br/>
<br/>
Cho S, Zheng Z, Park YK, Roh MR. The 308-nm excimer laser: a promising device for the treatment of childhood vitiligo. <br/>
Photodermatol Photoimmunol Photomed. 2011 Feb;27(1):24-9.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                    className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo"><b>GENNAIO 2011<br/>
<br/>
</b>Toossi P, Shahidi-Dadras M, Mahmoudi Rad M, Fesharaki R. Non-cultured melanocyte-keratinocyte transplantation for the treatment of vitiligo: a clinical trial in an Iranian population. J Eur Acad Dermatol Venereol. 2011 Jan 9.<br/>
<br/>
Hu JJ, Xu AE, Wu XG, Sun XC, Luo XY. Small-sized lesions of childhood vitiligo treated by autologous epidermal grafting. <br/>
J Dermatolog Treat. 2011 Jan 22.<br/>
<br/>
Sun X, Qian G, Wu Y, Shen H, Wang T, Hu J, Guo B, Wu J, Xu A. Transplantation of autologous minigrafts for the treatment of stable vitiligo. J Dermatolog Treat. 2011 Jan 22.<br/>
<br/>
Mohanty S, Kumar A, Dhawan J, Sreenivas V, Gupta S. Non-cultured extracted hair follicle outer root sheath cell suspension for transplantation in vitiligo. Br J Dermatol. 2011 Jan 28.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------<strong><br/>
</strong></span><br/>
                                    <span className="Testo"> <b>DICEMBRE 2010</b><br/>
<br/>
Santaguida MG, Del Duca SC, Virili C, Gargano L, Centanni M. The presence of non-segmental vitiligo modifies intracellular cytokine subsets in patients with chronic lymphocytic thyroiditis. Int J Immunopathol Pharmacol. 2010 Oct-Dec;23(4):1203-9.<br/>
<br/>
El-Khateeb EA, Ragab NF, Mohamed SA. Epidermal photoprotection: comparative study of narrowband ultraviolet B minimal erythema doses with and without stratum corneum stripping in normal and vitiligo skin. Clin Exp Dermatol. 2010 Dec 24.<br/>
<br/>
Czarnowicki T, Harari M, Ruzicka T, Ingber A. Dead Sea climatotherapy for vitiligo: a retrospective study of 436 patients. J Eur Acad Dermatol Venereol. 2010 Dec 5.<br/>
<br/>
Lee DY, Kim CR, Lee JH, Yang JM. Recent onset vitiligo treated with systemic corticosteroid and topical tacrolimus: Need for early treatment in vitiligo. J Dermatol. 2010 Dec;37(12):1057-9.<br/>
<br/>
Eves PC, Baran M, Bullett NA, Way L, Haddow D, Neil SM. Establishing a Transport Protocol for the Delivery of Melanocytes and Keratinocytes for the Treatment of Vitiligo. Tissue Eng Part C Methods. 2010 Dec 7.<br/>
<br/>
van Geel N, Wallaeys E, Goh BK, De Mil M, Lambert J. Long-term results of noncultured epidermal cellular grafting in vitiligo, halo naevi, piebaldism and naevus depigmentosus. Br J Dermatol. 2010 Dec;163(6):1186-93.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------<br/>
</span><br/>
                                    <span className="Testo"><b>SETTEMBRE 2009</b><br/>
<br/>
Lee DY, Choi SC, Lee JH. Comparison of suction blister and carbon dioxide laser for recipient site preparation in epidermal grafting of segmental vitiligo. Clin Exp Dermatol. 2009 Sep 15.<br/>
<br/>
Kossakowska MM, Cieścińska C, Jaszewska J, Placek WJ. Control of negative emotions and its implication for illness perception among psoriasis and vitiligo patients. J Eur Acad Dermatol Venereol. 2009 Sep 10.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                    <span className="Testo"><br/>
<b>AGOSTO 2009</b><br/>
<br/>
Stinco G, Piccirillo F, Forcione M, Valent F, Patrone P. An open randomized study to compare narrow band UVB, topical pimecrolimus and topical tacrolimus in the treatment of vitiligo. Eur J Dermatol. 2009 Aug 4.<br/>
<br/>
Gowda S, Tillman DK, Fitzpatrick JE, Gaspari AA, Goldenberg G. Imiquimod-induced vitiligo after treatment of nodular basal cell carcinoma. J Cutan Pathol. 2009 Aug; 36(8): 878-81.<br/>
<br/>
Radakovic S, Breier-Maly J, Konschitzky R, Kittler H, Sator P, Hoenigsmann H, Tanew A. Response of vitiligo to once- vs. twice-daily topical tacrolimus: a controlled prospective, randomized, observer-blinded trial. J Eur Acad Dermatol Venereol. 2009 Aug; 23(8): 951-3.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                    <span className="Testo"><br/>
<b>LUGLIO 2009</b><br/>
<br/>
Lee DY, Choi SC, Lee JH. Generalized vitiligo treated by combination therapy of epidermal graft and systemic corticosteroid. Clin Exp Dermatol. 2009 Jul 29.<br/>
<br/>
Saraceno R, Nisticò SP, Capriotti E, Chimenti S. Monochromatic excimer light 308 nm in monotherapy and combined with topical khellin 4% in the treatment of vitiligo: a controlled study. Dermatol Ther. 2009 Jul-Aug; 22(4) :391-4.<br/>
<br/>
Elgoweini M, Nour El Din N. Response of vitiligo to narrowband ultraviolet B and oral antioxidants. J Clin Pharmacol. 2009 Jul; 49(7): 852-5.<br/>
<br/>
Yuksel EP, Aydin F, Senturk N, Canturk T, Turanli AY. Comparison of the efficacy of narrow band ultraviolet B and narrow band ultraviolet B plus topical catalase-superoxide dismutase treatment in vitiligo patients. Eur J Dermatol. 2009 Jul-Aug; 19(4): 341-4.<br/>
<br/>
Fongers A, Wolkerstorfer A, Nieuweboer-Krobotova L, Krawczyk P, Tóth GG, van der Veen JP. Long-term results of 2-mm punch grafting in patients with vitiligo vulgaris and segmental vitiligo: effect of disease activity. Br J Dermatol. 2009 Jun 22.<br/>
<br/>
Hui-Lan Y, Xiao-Yan H, Jian-Yong F, Zong-Rong L. Combination of 308-nm excimer laser with topical pimecrolimus for the treatment of childhood vitiligo. Pediatr Dermatol. 2009 May-Jun; 26(3): 354-6.<br/>
<br/>
Farajzadeh S, Daraei Z, Esfandiarpour I, Hosseini SH. The efficacy of pimecrolimus 1% cream combined with microdermabrasion in the treatment of nonsegmental childhood vitiligo: a randomized placebo-controlled study. Pediatr Dermatol. 2009 May-Jun; 26(3): 286-91.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                    <span className="Testo"><br/>
<b>GIUGNO 2009</b><br/>
<br/>
Borlu M, Cölgeçen E, Evereklioglu C. Behçet's disease and vitiligo in two brothers: coincidence or association? Clin Exp Dermatol. 2009 Jun 22.<br/>
<br/>
Lubaki LJ, Ghanem G, Vereecken P, Fouty E, Benammar L, Vadoud-Seyedi J, Dell'anna ML, Briganti S, Picardo M, Heenen M. Time-kinetic study of repigmentation in vitiligo patients by tacrolimus or pimecrolimus. Arch Dermatol Res. 2009 Jun 23.<br/>
<br/>
El Hoseny SM. Treatment of Upper- and Lower-Extremity Vitiligo with Epidermal Grafts After CO(2) Laser Resurfacing with Systemic and Topical Steroids. Aesthetic Plast Surg. 2009 Jun 16.<br/>
<br/>
Bakis-Petsoglou S, Le Guay JL, Wittal R. A randomized, double-blinded, placebo-controlled trial of pseudocatalase cream and narrowband ultraviolet B in the treatment of vitiligo. Br J Dermatol. 2009 Jun 11.<br/>
<br/>
Akar A, Tunca M, Koc E, Kurumlu Z. Broadband targeted UVB phototherapy for localized vitiligo: a retrospective study. Photodermatol Photoimmunol Photomed. 2009 Jun; 25(3): 161-3.<br/>
<br/>
Anbar TS, El-Sawy AE, Attia SK, Moftah NH, El-Tonsy MH. Patterns of repigmentation in two cases of hypopigmented type of vitiligo. Photodermatol Photoimmunol Photomed. 2009 Jun; 25(3): 156-8.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span><br/>
                                    <span className="Testo"><br/>
<b>MAGGIO 2009</b><br/>
<br/>
Berti S, Buggiani G, Lotti T. Use of tacrolimus ointment in vitiligo alone or in combination therapy. Skin Therapy Lett. 2009 May; 14(4) :5-7.<br/>
<br/>
Welsh O, Herz-Ruelas ME, Gómez M, Ocampo-Candiani J. Therapeutic evaluation of UVB-targeted phototherapy in vitiligo that affects less than 10% of the body surface area. Int J Dermatol. 2009 May; 48(5): 529-34.<br/>
<br/>
Back C, Dearman B, Li A, Neild T, Greenwood JE. Noncultured keratinocyte/melanocyte cosuspension: effect on reepithelialization and repigmentation--a randomized, placebo-controlled study. J Burn Care Res. 2009 May-Jun; 30(3): 408-16.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                </em></u><em><span className="Testo"><br/>
<b>APRILE 2009</b><br/>
<br/>
Ko WC, Chen YF. Suction blister epidermal grafts combined with CO2 laser superficial ablation as a good method for treating small-sized vitiligo. Dermatol Surg. 2009 Apr; 35(4): 601-6.<br/>
<br/>
Lv Y, Zhang Y, Luan Q. Supplementation with insulin and antioxidants could be advantageous in the treatment of vitiligo. Med Hypotheses. 2009 Apr; 72(4): 480-1.<br/>
<br/>
Kapoor R, Phiske MM, Jerajani HR. Evaluation of safety and efficacy of topical prostaglandin E2 in treatment of vitiligo. Br J Dermatol. 2009 Apr; 160(4): 861-3.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                </em></u><em><span className="Testo"><br/>
<b>MARZO 2009</b><br/>
<br/>
Al-Otaibi SR, Zadeh VB, Al-Abdulrazzaq AH, Tarrab SM, Al-Owaidi HA, Mahrous R, Kadyan RS, Najem NM. Using a 308-nm excimer laser to treat vitiligo in Asians. Acta Dermatovenerol Alp Panonica Adriat. 2009 Mar; 18(1): 13-9.<br/>
<br/>
Eryilmaz A, Seçkin D, Baba M. Pimecrolimus: a new choice in the treatment of vitiligo? J Eur Acad Dermatol Venereol. 2009 Mar 4.<br/>
<br/>
Kishan Kumar YH, Rao GR, Gopal KV, Shanti G, Rao KV. Evaluation of narrow-band UVB phototherapy in 150 patients with vitiligo. Indian J Dermatol Venereol Leprol. 2009 Mar-Apr; 75(2): 162-6.<br/>
<br/>
Hexsel CL, Mahmoud BH, Mitchell D, Rivard J, Owen M, Strickland FM, Lim HW, Hamzavi I. A clinical trial and molecular study of photoadaptation in vitiligo. Br J Dermatol. 2009 Mar; 160(3): 534-9.<br/>
<br/>
-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                    <span className="Testo"><br/>
</span></em></u><em><span className="Testo"><b>FEBBRAIO 2009</b><br/>
<br/>
Lee DY, Park JH, Choi SC, Lee JH. Comparison of recipient site preparations in epidermal grafting for vitiligo: suction blister and CO laser. J Eur Acad Dermatol Venereol. 2009 Feb 26.<br/>
<br/>
Sehgal VN. Role of tacrolimus (FK506) 0.1% ointment WW in vitiligo in children and imperatives of combine therapy with Trioxsalen and Silymarin suspension in progressive vitiligo. J Eur Acad Dermatol Venereol. 2009 Feb 31.<br/>
<br/>
Rodríguez-Martín M, García Bustínduy M, Sáez Rodríguez M, Noda Cabrera A. Randomized, double-blind clinical trial to evaluate the efficacy of topical tacalcitol and sunlight exposure in the treatment of adult nonsegmental vitiligo. Br J Dermatol. 2009 Feb; 160(2): 409-14.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                    <span className="Testo"><br/>
</span></em></u><em><span className="Testo"><b>GENNAIO 2009</b></span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">Lee DY, Park JH, Park HY, Lee JH. The use of suction blisters for the recipient area in epidermal grafting for vitiligo. J Eur Acad Dermatol Venereol. 2009 Jan 13.<br/>
<br/>
Lee KJ, Lee JH, Lee DY. Successful treatment of vitiligo in a 6-year-old child by topical anaesthesia and epidermal grafting. J Eur Acad Dermatol Venereol. 2009 Jan 8.<br/>
<br/>
Xu AE, Zhang DM, Wei XD, Huang B, Lu LJ. Efficacy and safety of tarcrolimus cream 0.1% in the treatment of vitiligo. Int J Dermatol. 2009 Jan; 48(1): 86-90.<br/>
<br/>
Mulekar SV, Al Issa A, Al Eisa A. Treatment of vitiligo on difficult-to-treat sites using autologous noncultured cellular grafting. Dermatol Surg. 2009 Jan; 35(1): 66-71.<br/>
<br/>
Esfandiarpour I, Ekhlasi A, Farajzadeh S, Shamsadini S. The efficacy of pimecrolimus 1% cream plus narrow-band ultraviolet B in the treatment of vitiligo: a double-blind, placebo-controlled clinical trial. J Dermatolog Treat. 2009; 20(1): 14-8.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u></p>
                            <p><u>
                            </u></p>
                            <table width="100%" border="0">
                                <tbody>
                                <tr>
                                    <td bgcolor="#dcf1f5">
                                        <div align="right"><em><a href="#inizio"><span className="Titolo">inizio pagina ↑</span></a></em>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <u>
                            </u><p></p>
                            <p><u><br/>
                            </u></p>
                            <p><u> </u></p>
                            <p><em><a name="3" id="studiRicercaBase"></a><strong>STUDI DI RICERCA DI BASE<br/>
<br/>
</strong></em></p>
                            <p><u> </u></p>
                            <p><span className="Testo"><b>GIUGNO 2011</b><br/>
<br/>
Tu CX, Jin WW, Lin M, Wang ZH, Man MQ. Levels of TGF-β(1) in serum and culture supernatants of CD4(+)CD25 (+) T cells from patients with non-segmental vitiligo. Arch Dermatol Res. 2011 Jun 5.<br/>
<br/>
Esmaeili B, Rezaee SA, Layegh P, Tavakkol Afshari J, Dye P, Ghayoor Karimiani E, Kalalinia F, Rafatpanah H. Expression of IL-17 and COX2 Gene in Peripheral Blood Leukocytes of Vitiligo Patients. Iran J Allergy Asthma Immunol. 2011 Jun;10(2):81-9.<br/>
<br/>
Kim JY, Do JE, Ahn KJ, Noh S, Jee HJ, Oh SH. Detection of melanocyte autoantigens reacting with autoantibodies in vitiligo patients by proteomics. J Dermatol Sci. 2011 Jun;62(3):202-4.<br/>
<br/>
Elassiuty YE, Klarquist J, Speiser J, Yousef RM, El Refaee AA, Hunter NS, Shaker OG, Gundeti M, Nieuweboer-Krobotova L, Le Poole IC. Heme oxygenase-1 expression protects melanocytes from stress-induced cell death: implications for vitiligo. Exp Dermatol. 2011 Jun;20(6):496-501.<br/>
<br/>
</span><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                className="Testo"><strong><br/>
</strong></span></em></u><span className="Testo"><br/>
<br/>
<b>MAGGIO 2011</b><br/>
<br/>
Zhou M, Guan C, Lin F, Xu W, Fu L, Hong W, Wan Y, Xu A. Immunodetection of the MCHR1 antibody in vitiligo patient sera. Int J Mol Med. 2011 May;27(5):725-9.<br/>
<br/>
Deo SS, Bhagat AR, Shah RN. Genetic variations in nalp1 mRNA expressions in human vitiligo. Indian J Dermatol. 2011 May;56(3):266-71.<br/>
<br/>
Guarneri F, Asmundo A, Sapienza D, Cannavò SP. Glutathione S-transferase M1/T1 gene polymorphisms and vitiligo in a Mediterranean population. Pigment Cell Melanoma Res. 2011 May 26.<br/>
<br/>
Wu CS, Lan CC, Yu HS. Narrow-band UVB irradiation stimulates the migration and functional development of vitiligo-IgG antibodies-treated pigment cells. J Eur Acad Dermatol Venereol. 2011 May 4.<br/>
<br/>
</span><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><span
                                className="Testo"><br/>
<br/>
<b>APRILE 2011</b><br/>
<br/>
Bassiouny DA, Shaker O. Role of interleukin-17 in the pathogenesis of vitiligo. Clin Exp Dermatol. 2011 Apr;36(3):292-7.<br/>
<br/>
Wang CQ, Cruz-Inigo AE, Fuentes-Duculan J, Moussai D, Gulati N, Sullivan-Whalen M, Gilleaudeau P, Cohen JA, Krueger JG. Th17 cells and activated dendritic cells are increased in vitiligo lesions. PLoS One. 2011 Apr 25;6(4).<br/>
<br/>
</span><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><span
                                className="Testo"><br/>
<br/>
<b>MARZO 2011</b><br/>
<br/>
Tippisetty S, Ishaq M, Komaravalli PL, Jahan P. Angiotensin converting enzyme (ACE) gene polymorphism in vitiligo: protective and predisposing effects of genotypes in disease susceptibility and progression. Eur J Dermatol. 2011 Mar 22.<br/>
<br/>
Kim NH, Torchia D, Rouhani P, Roberts B, Romanelli P. Tumor necrosis factor-α in vitiligo: direct correlation between tissue levels and clinical parameters. Cutan Ocul Toxicol. 2011 Mar 9.<br/>
<br/>
Elassiuty YE, Klarquist J, Speiser J, Yousef RM, El Refaee AA, Hunter NS, Shaker OG, Gundeti M, Nieuweboer-Krobotova L, Caroline Le Poole I. Heme oxygenase-1 expression protects melanocytes from stress-induced cell death: implications for vitiligo. Exp Dermatol. 2011 Mar 22.<br/>
<br/>
Kumar R, Parsad D, Kanwar AJ, Kaul D. Altered levels of Ets-1 transcription factor and metalloproteinases in melanocytes from vitiligo patients. Br J Dermatol. 2011 Mar 24.<br/>
<br/>
Lai LG, Xu AE. In vivo reflectance confocal microscopy imaging of vitiligo, nevus depigmentosus and nevus anemicus. Skin Res Technol. 2011 Mar 24.<br/>
<br/>
Singh S, Singh U, Pandey SS. Increased level of serum Homocysteine in vitiligo. J Clin Lab Anal. 2011;25(2):110-2.<br/>
<br/>
Zhou Q, Wang F, Zhang Y, Yang F, Wang Y, Sun S. Down-regulation of Prdx6 contributes to DNA vaccine induced vitiligo in mice. Mol Biosyst. 2011 Mar 1;7(3):809-16.<br/>
<br/>
Douroudis K, Kingo K, Karelson M, Silm H, Reimann E, Traks T, Vasar E, Kõks S. The PRO2268 Gene as a Novel Susceptibility Locus for Vitiligo. Acta Derm Venereol. 2011 Mar;91(2):189-191.</span><br/>
                                <br/>
                                <em><span
                                    className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                    className="Testo"><strong><br/>
</strong></span></em></u><u><em><span className="Testo"><strong><br/>
</strong></span></em></u><em><span className="Testo"><b>FEBBRAIO 2011</b></span></em><u><em><span
                                    className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo">Czajkowski R. BRAF, HRAS, KRAS, NRAS and CDKN2A genes analysis in cultured melanocytes used for vitiligo treatment. Int J Dermatol. 2011 Feb;50(2):180-3.<br/>
<br/>
van den Boorn JG, Picavet DI, van Swieten PF, van Veen HA, Konijnenberg D, van Veelen PA, van Capel T, Jong EC, Reits EA, Drijfhout JW, Bos JD, Melief CJ, Luiten RM. Skin-Depigmenting Agent Monobenzone Induces Potent T-Cell Autoimmunity toward Pigmented Cells by Tyrosinase Haptenation and Melanosome Autophagy. J Invest Dermatol. 2011 Feb 17.<br/>
<br/>
Jin Y, Birlea SA, Fain PR, Gowan K, Riccardi SL, Holland PJ, Bennett DC, Herbstman DM, Wallace MR, McCormack WT, Kemp EH, Gawkrodger DJ, Weetman AP, Picardo M, Leone G, Taïeb A, Jouary T, Ezzedine K, van Geel N, Lambert J, Overbeck A, Spritz RA. Genome-Wide Analysis Identifies a Quantitative Trait Locus in the MHC Class II Region Associated with Generalized Vitiligo Age of Onset. J Invest Dermatol. 2011 Feb 17.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                    className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo"><b>GENNAIO 2011</b></span></em><u><em><span
                                    className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo">Hu DY, Ren YQ, Zhu KJ, Lv YM, Cheng H, Zhang Z, Li Y, He SM, Tang J, Liu JL, Lin Y, Sun YY, Zuo XB, Chen G, Sun LD, Yang S, Zhang XJ. Comparisons of clinical features of HLA-DRB1*07 positive and negative vitiligo patients in Chinese Han population. J Eur Acad Dermatol Venereol. 2011 Jan 17.<br/>
<br/>
Liu XM, Zhou Q, Xu SZ, Wakamatsu K, Lei TC. Maintenance of immune hyporesponsiveness to melanosomal proteins by DHICA-mediated antioxidation: Possible implications for autoimmune vitiligo. Free Radic Biol Med. 2011 Jan 21.<br/>
<br/>
Kumar R, Parsad D, Kanwar AJ. Role of apoptosis and melanocytorrhagy: a comparative study of melanocyte adhesion in stable and unstable vitiligo. Br J Dermatol. 2011 Jan;164(1):187-91.<br/>
<br/>
Kemp EH, Emhemad S, Akhtar S, Watson PF, Gawkrodger DJ, Weetman P. Autoantibodies against tyrosine hydroxylase in patients with non-segmental (generalised) vitiligo. Exp Dermatol. 2011 Jan;20(1):35-40.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                    className="Testo"><strong><br/>
</strong><br/>
<br/>
</span></em></u><em><span className="Testo"><b>DICEMBRE 2010</b></span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">Prignano F, Ricceri F, Bianchi B, Guasti D, Bonciolini V, Lotti T, Pimpinelli N. Dendritic cells: ultrastructural and immunophenotypical changes upon nb-UVB in vitiligo skin. Arch Dermatol Res. 2010 Dec 21.<br/>
<br/>
Zhou Q, Wang F, Zhang Y, Yang F, Wang Y, Sun S. Down-regulation of Prdx6 contributes to DNA vaccine induced vitiligo in mice. Mol Biosyst. 2010 Dec 10.<br/>
<br/>
Kim NH, Lee AY. Histamine effect on melanocyte proliferation and vitiliginous keratinocyte survival. Exp Dermatol. 2010 Dec;19(12):1073-9.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><span
                                    className="Testo"><strong><br/>
<br/>
</strong></span></em></u><em><span className="Testo"><b>SETTEMBRE 2009</b></span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">Aksoy SN, Erbagci Z, Saygili EI, Sever T, Erbagci AB, Pehlivan S. Analysis of myeloperoxidase promotor polymorphism and enzyme activity in Turkish patients with vitiligo. Eur J Dermatol. 2009 Sep 17.<br/>
<br/>
Jin Y, Riccardi SL, Gowan K, Fain PR, Spritz RA. Fine-Mapping of Vitiligo Susceptibility Loci on Chromosomes 7 and 9 and Interactions with NLRP1 (NALP1). J Invest Dermatol. 2009.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                    <span className="Testo"><br/>
</span></em></u><em><span className="Testo"><b>AGOSTO 2009</b></span></em><u><em><span className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">Hariharan V, Klarquist J, Reust MJ, Koshoffer A, McKee MD, Boissy RE, Le Poole IC. Monobenzyl Ether of Hydroquinone and 4-Tertiary Butyl Phenol Activate Markedly Different Physiological Responses in Melanocytes: Relevance to Skin Depigmentation. J Invest Dermatol. 2009 Aug 6.<br/>
<br/>
Anbar TS, Abdel-Raouf H, Awad SS, Ragaie MH, Abdel-Rahman AT. The hair follicle melanocytes in vitiligo in relation to disease duration. J Eur Acad Dermatol Venereol. 2009 Aug; 23(8): 934-9.</span></em><u><em><span
                                    className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">-------------------------------------------------------------------------------------------------------------------------------------------------------------------</span></em><u><em><br/>
                                    <span className="Testo"><br/>
</span></em></u><em><span className="Testo"><b>LUGLIO 2009</b></span></em><u><em><span className="Testo"><br/>
<br/>
</span></em></u><em><span className="Testo">Kumar R, Parsad D, Kaul D, Kanwar A. Liver X receptor expression in human me          </span></em>
                            </p></div>
                        <em>
                        </em></div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default DallaRicerca;