import './Menu.css'
import menu_bottom from '../images/right1.jpg'
import { NavLink } from 'react-router-dom';

function Menu(){
    return(
        <>
            <div className='menu'>
                <ul className='menu_list'>

                    <li className='point'>
                        <NavLink exact activeClassName='active' className='point' to='/homepage'>Home</NavLink>
                    </li>
                    <li className='point'>
                        <NavLink exact activeClassName='active' className='point' to='/dalla_ricerca'>Dalla ricerca</NavLink>
                    </li>
                    <li className='point'>
                        <NavLink exact activeClassName='active' className='point' to='/comitato_scientifico'>Comitato scientifico</NavLink>
                    </li>
                    <li className='point'>
                        <NavLink exact activeClassName='active' className='point' to='/domande_e_risposte'>Domande e risposte</NavLink>
                    </li>
                    <li className='point'>
                        <NavLink exact activeClassName='active' className='point' to='/la_tua_storia'>La tua storia</NavLink>
                    </li>
                    <li className='point'>
                        <NavLink exact activeClassName='active' className='point' to='/contatti'>Contatti</NavLink>
                    </li>
                </ul>
                <div className='bottom_image_div'>
                    <img className='bottom_image' src={menu_bottom}/>
                </div>
                <a className='facebook_link_image' href=''></a>
                <a className='dalla_ricerca_link_image' href='/dalla_ricerca'></a>
            </div>
        </>
    )
}

export default Menu;