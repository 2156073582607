import './Contatti.css'
import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import React from "react";

function Contatti(){
    return(
        <div className='contatti'>
            <Header/>
            <div className='contatti_content'>
                <Menu/>
                <div className='contatti_filling'>
                    <div id="mainarea">
                        <div className="catgroupnamearea">
                            <h1 className="heading">Contatti » </h1>
                        </div>

                        <div id="maincontarea">
                            <p><span className="Testo"><span className="Titolo">A.R.I.V. Onlus</span><br/>
                            </span><span className="Testo"><span className="SottoTitolo">Associazione Italiana Ricerca e Informazione per la Vitiligine - ONLUS - </span><br/>
                            <br/>
                            Via Borromeo, 29<br/>
                            20031 Cesano Maderno (MI)<br/>
                            <br/>
                            Per info contatta:<br/>
                            <a href=""><b>direttivo@arivonlus.it</b></a><br/>
                            <a href=""><b>presidente@arivonlus.it</b></a><br/>
                            <br/>
                            </span><span className="Testo"> p.i. 02999070135<br/>
                            <br/>
                            <br/>
                            <br/>
                            </span></p>
                            <p>
                                <iframe className="_iub_cs_activate" scrolling="no" height="350" frameBorder="0"
                                        width="550" marginHeight="0" marginWidth="0"
                                        suppressedsrc="http://maps.google.it/maps?f=q&amp;source=s_q&amp;hl=it&amp;geocode=&amp;q=Via+Borromeo,+29+20031+Cesano+Maderno+(MI)&amp;aq=&amp;sll=41.442726,12.392578&amp;sspn=24.229039,39.506836&amp;ie=UTF8&amp;hq=&amp;hnear=Via+Borromeo,+29,+20031+Cesano+Maderno+Monza+e+Brianza,+Lombardia&amp;ll=45.628868,9.147146&amp;spn=0.001343,0.002411&amp;z=14&amp;iwloc=A&amp;output=embed"
                                        data-ruffle-polyfilled=""></iframe>
                                <br/>
                                <small><a>
                                    <span className="Titolo">Visualizzazione ingrandita della mappa</span></a></small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contatti;