import './Privacy.css'

function Privacy(){
    return(
        <>
        <div className='background'></div>
            <div className='privacy'>
                <div className="heading_info">
                    <h1 className='privacy_h1'>
                        Privacy Policy di <strongest>www.arivonlus.it</strongest>
                    </h1>
                    <p>
                        Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.
                    </p>
                </div>
                <h1 className='privacy_2nd'>Dati personali raccolti per le seguenti finalità ed utilizzando i seguenti
                    servizi:</h1>

                <ul className='privacy_list'>
                    <li className='privacy_li'>
                        <heading_t>Contattare l'Utente</heading_t>
                        <gr_t>Mailing List o Newsletter</gr_t>
                        <p className='privacy_li_p'>Dati Personali: CAP, città, cognome, email, nome e numero di
                            telefono</p>
                    </li>

                    <li className='privacy_li'>
                        <heading_t>Visualizzazione di contenuti da piattaforme esterne</heading_t>
                        <gr_t>Widget Google Maps</gr_t>
                        <p className='privacy_li_p'>Dati Personali: Cookie e Dati di utilizzo</p>
                    </li>

                    <li className='privacy_li'>
                        <heading_t>Statistica</heading_t>
                        <gr_t>Google Analytics</gr_t>
                        <p className='privacy_li_p'>Dati Personali: Cookie e Dati di utilizzo</p>
                    </li>
                </ul>
                <h1 className='privacy_2nd'>
                    Informazioni di contatto
                </h1>
                <li className='privacy_li'>
                    <heading_t>Titolare del trattamento</heading_t>
                    <p className='privacy_li_p'>ARIV - Associazione Italiana Ricerca e Informazione per la Vitiligine -
                        ONLUS - Via Borromeo, 29 - 20031 Cesano Maderno (MI), presidente@arivonlus.it</p>
                </li>
                <span className='line'></span>
                <div className="iub_footer">

                    <p className='footer_privacy_p'>
                        Ultima modifica: 18 Giugno 2015
                    </p>


                    <p className='footer_privacy_a'>
                        <a href=""
                           className='footer_privacy_p'
                           title="iubenda - generatore di Privacy Policy" target="_blank">iubenda</a> ospita questa
                        pagina e raccoglie
                        <a href=""
                           className='footer_privacy_a'
                           target="_blank">alcuni dati personali</a> sugli utenti
                    </p>

                    <a href=""
                       title="Visualizza la Privacy Policy Completa"
                       className="show_comp_link iframe-preserve an-preserve">Visualizza la Privacy Policy Completa</a>

                </div>
            </div>
        </>

    )
}

export default Privacy;