import DescriptionToggle from "../DescriptionToggle/DescriptionToggle";
import React from "react";

function PROBLEMI() {
    const desc1 = <span className="Testo">Lo sfiguramento che ne consegue può intaccare il benessere psicologico ed emozionale creando difficoltà sociali e in campo lavorativo, in modo particolare quando le chiazze sono su viso, mani, braccia, piedi o sui genitali in persone con la pelle scura o che si abbronzano facilmente.
            <br/>
            Gli adolescenti, spesso preoccupati del loro aspetto, possono vergognarsi per l’estendersi della malattia e sentirsi imbarazzati, depressi, preoccupati per come gli altri possono reagire. Parlare con gli altri che hanno la vitiligine può aiutare; medici, famiglia ed amici comprensivi sono importanti ed offrono grande aiuto.</span>
    const desc2 = <span className="Testo">Non dobbiamo dimenticare che la pelle con vitiligine è priva della melanina, che è la sua fotoprotezione naturale.
            <br/>
            Se si sta al sole per più di pochi minuti, è bene usare gli schermi solari, perché proteggono la pelle da scottature e impediscono alla pelle normale di scurirsi troppo, accentuando il contrasto. Alcuni medici ritengono utile 15 minuti di esposizione alla luce solare tre o quattro volte alla settimana.
            <br/>
            Esistono vari prodotti specifici di Camouflage che colorano le chiazze bianche  con ottimi risultati,migliorando decisamente l’aspetto e il morale. Il Camouflage  ha la dignità ed il valore di una prestazione medica, ha sentenziato un grande e sensibile dermatologo-qualora non vi siano altre opzioni valide per risolvere  la malattia.
            <br/>
            Con un po’ di pazienza e l’aiuto di un esperto si può trovare, tra tinture, coloranti immediati, gel e cover creams, quello che funziona bene per il proprio caso e che risolve al meglio il problema estetico.</span>
    const desc3 = <span className="Testo">Sicuramente un bravo psicologo può aiutare ad affrontare una situazione difficile, a ritrovare l’autostima perduta e gestire i problemi sociali ed emotivi che molti pazienti si ritrovano a dover fronteggiare.</span>
    const desc4 =<td class="DomRisp"><span class="Testo">Bisogna capire che molti non sanno di cosa si tratta: non bisogna aver paura di spiegare, specialmente ai bambini, che è una malattia per cui il sistema immunitario vede come nemici le cellule che producono il pigmento e le attacca. Si può aggiungere che non fa male, che è una malattia genetica e che non è contagiosa.
            <br/>
            Infine, è bene condurre uno stile di vita normale: se ti piace fare sport, la vita all’aria aperta, nuotare, continua con queste attività (usando magari lo schermo solare e il Camouflage). E’ buona norma non chiudersi in se stessi, non evitare la gente o gli eventi sociali che sono benefici e fondamentali  per il nostro benessere psicologico.</span></td>
    const desc5 = <span class="Testo">È bene essere chiari e aperti, spiegare che è una malattia genetica e che il nostro sistema immunitario vede i melanociti come estranei e li attacca, che è una malattia piuttosto comune, che non fa male, non prude e non è contagiosa. Si può spiegare che le cure stanno migliorando e che dalla ricerca genetica verrà un giorno la cura risolutiva.
            <br/>
            Tutti conoscono malattie genetiche come il cancro, la leucemia, la sclerosi multipla, il Parkinson per cui, quando si rendono conto che anche questa è una condizione che uno può ereditare, ne hanno meno paura.</span>
    const desc6 =<td class="DomRisp"><span class="Testo">I bambini di solito accettano meglio la malattia quando i genitori sono aperti e disponibili a parlarne in modo chiaro e veritiero. Fingere di non notare qualcosa che è evidente può rendere a tuo figlio difficile il parlarne.
            <br/>
            È importante parlarne con gli insegnanti, con lo psicologo, sapere se a scuola qualcuno prende in giro o schernisce per intervenire ad educare e di conseguenza a correggere questi atteggiamenti.
            <br/>
            Il bambino va aiutato, incoraggiato in tutte le sue attività e nei suoi hobbies. È bene insegnare che esistono le differenze, e che siamo tutti uguali e nello stesso tempo tutti diversi.</span></td>
    const desc7 =<span class="Testo">Se sei o sei stato oggetto di discriminazioni per la tua vitiligine, denuncia il fatto circonstanziato ad ARIV che è in contatto con le autorità competenti. C’è ancora molta ignoranza e diffidenza per le malattie della pelle in generale,per la vitiligo in particolare. In quanto ai problemi relazionali, sono molto comuni in chi ha la vitiligo. Ci si preoccupa o ci si convince di non esser attraenti, mentre al contrario mariti / mogli / partners / amici / parenti dichiarano sinceramente di non esserne minimamente disturbati.
            <br/>
            La pelle non è l’unico motivo di attrazione in una persona: ci sono il sorriso, i capelli, la simpatia, l’eleganza, l’intelligenza, la sensibilità, la capacità di ascoltare e di comunicare. Non permettere che la malattia ti impedisca di svolgere attività che ti danno gioia. Se qualcuno ti chiede qualcosa, spiega semplicemente come stanno le cose.
            <br/>
            Una terapia psicologica di supporto può essere di enorme aiuto, cerca un bravo psicologo, esperto, con il quale affrontare la condizione sia dal di dentro che dal di fuori, socialmente, e che lavori con te per mantenere alta, o riprenderti, la tua autostima e il tuo orgoglio.</span>

    return (
        <>
            <DescriptionToggle description='• Come posso far fronte allo stress emotivo causato dalla malattia?'
                               link={desc1}/>
            <DescriptionToggle description='• Devo usare degli schermi solari? E’ utile il camouflage?' link={desc2}/>
            <DescriptionToggle description='• È utile una terapia psicologica?' link={desc3}/>
            <DescriptionToggle
                description='• Qualcuno non stacca gli occhi dalle mie chiazze e io mi vergogno. Cosa posso fare?'
                link={desc4}/>
            <DescriptionToggle description='• Come devo spiegare agli altri cos’è la vitiligine?' link={desc5}/>
            <DescriptionToggle description='• Ho un bambino con la vitiligo…' link={desc6}/>
            <DescriptionToggle description={
                <>
                    • Ho paura di perdere il lavoro per la vitiligo…<br/>
                    Ho paura di non esser attraente per il/la mio partner…
                </>
            } link={desc7}/>
        </>
    )
}

export default PROBLEMI;